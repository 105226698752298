import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { 
  FiHome, 
  FiGrid, 
  FiActivity, 
  FiBarChart2, 
  FiFileText, 
  FiSettings, 
  FiRefreshCw,
  FiBell
} from 'react-icons/fi';

const SidebarContainer = styled.aside`
  width: var(--sidebar-width);
  background-color: var(--sidebar-bg);
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }
  
  @media (max-width: 768px) {
    width: 0;
    transform: translateX(-100%);
  }
`;

const Logo = styled.div`
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  background-color: var(--sidebar-bg);
  z-index: 101;
  margin-top: 0;
`;

const LogoIcon = styled.div`
  color: var(--primary);
`;

const Nav = styled.nav`
  padding: 1rem 0;
  flex: 1;
  overflow-y: auto;
`;

const NavLink = styled(Link)`
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--sidebar-text);
  text-decoration: none;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    text-decoration: none;
  }
  
  &.active {
    color: white;
    border-left-color: var(--primary);
    background-color: rgba(16, 185, 129, 0.1);
  }
`;

const NavSection = styled.div`
  margin-bottom: 1.5rem;
`;

const NavSectionTitle = styled.div`
  padding: 0.5rem 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0.5rem 1.5rem;
`;

// Update the mockAssets to remove the Seattle location
const mockAssets = [
  { id: 1, name: 'Mountain Resort E', type: 'Hospitality', risk: 'high' },
  { id: 2, name: 'Oceanside Hotel Complex', type: 'Hospitality', risk: 'critical' },
  { id: 3, name: 'Alpine Winery', type: 'Agriculture', risk: 'medium' },
  { id: 4, name: 'Urban Office Complex', type: 'Commercial Real Estate', risk: 'medium' }
];

const Sidebar = () => {
  const location = useLocation();
  const [assets, setAssets] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  useEffect(() => {
    // Load mock assets
    setAssets(mockAssets);
  }, []);
  
  return (
    <SidebarContainer>
      <Logo>
        <LogoIcon>
          <FiActivity />
        </LogoIcon>
        Mandjet
      </Logo>
      
      <Nav>
        <NavSection>
          <NavSectionTitle>Main</NavSectionTitle>
          <NavLink to="/dashboard" className={location.pathname === '/dashboard' ? 'active' : ''}>
            <FiHome /> Dashboard
          </NavLink>
          
          <NavLink to="/asset-list" className={location.pathname === '/asset-list' ? 'active' : ''}>
            <FiGrid /> Assets
          </NavLink>
          
          <NavLink to="/simulations" className={location.pathname === '/simulations' ? 'active' : ''}>
            <FiRefreshCw /> Simulations
          </NavLink>
          
          <NavLink to="/analytics" className={location.pathname === '/analytics' ? 'active' : ''}>
            <FiBarChart2 /> Analytics
          </NavLink>
          
          <NavLink to="/reports" className={location.pathname === '/reports' ? 'active' : ''}>
            <FiFileText /> Reports
          </NavLink>
        </NavSection>
        
        <NavSection>
          <NavSectionTitle>Account</NavSectionTitle>
          <NavLink to="/notifications" className={location.pathname === '/notifications' ? 'active' : ''}>
            <FiBell /> Notifications
          </NavLink>
          <NavLink to="/settings" className={location.pathname === '/settings' ? 'active' : ''}>
            <FiSettings /> Settings
          </NavLink>
        </NavSection>
      </Nav>
    </SidebarContainer>
  );
};

export default Sidebar; 