import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiHome, FiAlertCircle } from 'react-icons/fi';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  padding: 2rem;
`;

const ErrorIcon = styled.div`
  font-size: 4rem;
  color: var(--text-light);
  margin-bottom: 1.5rem;
`;

const ErrorTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text);
`;

const ErrorMessage = styled.p`
  font-size: 1.1rem;
  max-width: 500px;
  margin-bottom: 2rem;
  color: var(--text-light);
`;

const BackToHomeButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary);
  color: white;
  font-weight: 500;
  border-radius: 0.375rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--primary-dark);
  }
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      <ErrorIcon>
        <FiAlertCircle />
      </ErrorIcon>
      <ErrorTitle>404 - Page Not Found</ErrorTitle>
      <ErrorMessage>
        The page you are looking for does not exist or has been moved.
      </ErrorMessage>
      <BackToHomeButton to="/">
        <FiHome /> Back to Dashboard
      </BackToHomeButton>
    </NotFoundContainer>
  );
};

export default NotFound; 