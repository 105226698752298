import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiBell, FiAlertTriangle, FiBarChart2, FiFile, FiClock, FiCheck } from 'react-icons/fi';

const NotificationContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 400px;
  max-height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: ${props => props.$isOpen ? 'block' : 'none'};
  overflow: hidden;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border);
  background-color: var(--background);
`;

const NotificationTitle = styled.div`
  font-weight: 600;
`;

const NotificationCount = styled.div`
  font-size: 0.85rem;
  color: var(--text-light);
`;

const NotificationList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
`;

const NotificationItem = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border);
  background-color: ${props => 
    props.$priority === 'critical' && props.$unread 
      ? 'rgba(239, 68, 68, 0.1)' 
      : props.$unread 
        ? 'rgba(16, 185, 129, 0.05)' 
        : 'white'};
  cursor: pointer;
  
  &:hover {
    background-color: var(--background);
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

const NotificationContent = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const NotificationIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props => {
    switch (props.$type) {
      case 'risk':
        return props.$priority === 'critical' ? 'rgba(239, 68, 68, 0.2)' : 'rgba(239, 68, 68, 0.1)';
      case 'report':
        return 'rgba(16, 185, 129, 0.1)';
      case 'analytics':
        return 'rgba(59, 130, 246, 0.1)';
      default:
        return 'rgba(107, 114, 128, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.$type) {
      case 'risk':
        return 'rgb(239, 68, 68)';
      case 'report':
        return 'rgb(16, 185, 129)';
      case 'analytics':
        return 'rgb(59, 130, 246)';
      default:
        return 'rgb(107, 114, 128)';
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const NotificationBody = styled.div`
  flex: 1;
`;

const NotificationMessage = styled.div`
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  font-weight: ${props => props.$priority === 'critical' ? '600' : '400'};
`;

const NotificationMeta = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--text-light);
`;

const NotificationTime = styled.div``;

const NotificationAsset = styled.div`
  font-weight: 500;
  color: ${props => props.$priority === 'critical' ? 'var(--error)' : 'inherit'};
`;

const NotificationFooter = styled.div`
  padding: 0.75rem 1rem;
  text-align: center;
  border-top: 1px solid var(--border);
`;

const ViewAllLink = styled(Link)`
  color: var(--primary);
  font-weight: 500;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const NotificationsDropdown = ({ isOpen, notifications, markAsRead }) => {
  const unreadCount = notifications.filter(n => n.unread).length;
  
  // Sort notifications by priority (critical first) and then timestamp (most recent first)
  const sortedNotifications = [...notifications].sort((a, b) => {
    if (a.priority === 'critical' && b.priority !== 'critical') return -1;
    if (a.priority !== 'critical' && b.priority === 'critical') return 1;
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
  
  const getIcon = (type) => {
    switch (type) {
      case 'risk':
        return <FiAlertTriangle />;
      case 'report':
        return <FiFile />;
      case 'analytics':
        return <FiBarChart2 />;
      default:
        return <FiBell />;
    }
  };
  
  return (
    <NotificationContainer $isOpen={isOpen}>
      <NotificationHeader>
        <NotificationTitle>Notifications</NotificationTitle>
        <NotificationCount>{unreadCount} unread</NotificationCount>
      </NotificationHeader>
      
      <NotificationList>
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map(notification => (
            <NotificationItem 
              key={notification.id} 
              $unread={notification.unread}
              $priority={notification.priority}
              onClick={() => markAsRead(notification.id)}
            >
              <NotificationContent>
                <NotificationIcon 
                  $type={notification.type}
                  $priority={notification.priority}
                >
                  {getIcon(notification.type)}
                </NotificationIcon>
                <NotificationBody>
                  <NotificationMessage $priority={notification.priority}>
                    {notification.message}
                  </NotificationMessage>
                  <NotificationMeta>
                    <NotificationTime>
                      <FiClock style={{ marginRight: '4px', verticalAlign: 'middle' }} />
                      {notification.time}
                    </NotificationTime>
                    <NotificationAsset $priority={notification.priority}>
                      {notification.asset}
                    </NotificationAsset>
                  </NotificationMeta>
                </NotificationBody>
              </NotificationContent>
            </NotificationItem>
          ))
        ) : (
          <div style={{ padding: '1rem', textAlign: 'center', color: 'var(--text-light)' }}>
            No notifications
          </div>
        )}
      </NotificationList>
      
      <NotificationFooter>
        <ViewAllLink to="/notifications">
          View all notifications
        </ViewAllLink>
      </NotificationFooter>
    </NotificationContainer>
  );
};

export default NotificationsDropdown; 