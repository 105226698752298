import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { 
  FiPlus, 
  FiDownload, 
  FiFile, 
  FiFileText, 
  FiCalendar, 
  FiClock,
  FiCheck,
  FiLoader,
  FiAlertTriangle,
  FiMapPin,
  FiX
} from 'react-icons/fi';
import { savedReports, reportTemplates } from '../data/mockData';

// Styled components
const ReportsContainer = styled.div`
  padding: 1rem 0;
`;

const ReportsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const ActionButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  color: white;
  font-weight: 500;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--primary-dark);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid var(--border);
  margin-bottom: 1.5rem;
`;

const Tab = styled.button`
  background: none;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 0.95rem;
  cursor: pointer;
  color: ${props => props.$active ? 'var(--primary)' : 'var(--text-light)'};
  border-bottom: 2px solid ${props => props.$active ? 'var(--primary)' : 'transparent'};
  font-weight: ${props => props.$active ? '600' : '400'};
  
  &:hover {
    color: var(--primary);
  }
`;

const ReportsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const ReportCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
`;

const ReportIconHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  
  .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(16, 185, 129, 0.1);
    border-radius: 8px;
  }
`;

const ReportMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-light);
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
`;

const ReportActions = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 0.75rem;
`;

const ReportButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: ${props => props.$primary ? 'var(--primary)' : 'white'};
  color: ${props => props.$primary ? 'white' : 'var(--text)'};
  border: 1px solid ${props => props.$primary ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.$primary ? 'var(--primary-dark)' : 'var(--background)'};
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const StatusBadge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  background-color: ${props => 
    props.$status === 'completed' ? 'rgba(16, 185, 129, 0.1)' : 
    props.$status === 'in-progress' ? 'rgba(245, 158, 11, 0.1)' : 
    'rgba(99, 102, 241, 0.1)'
  };
  color: ${props => 
    props.$status === 'completed' ? 'var(--success)' : 
    props.$status === 'in-progress' ? 'var(--warning)' : 
    'var(--secondary)'
  };
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
  
  p {
    color: var(--text-light);
    margin-bottom: 1.5rem;
  }
  
  .icon {
    font-size: 2.5rem;
    color: var(--text-light);
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  padding: 1.5rem;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-light);
    
    &:hover {
      color: var(--text);
    }
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  input, select {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 1rem;
    
    &:focus {
      outline: none;
      border-color: var(--primary);
      box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.2);
    }
  }
`;

const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const TemplateCard = styled.div`
  border: 2px solid ${props => props.$selected ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${props => props.$selected ? 'rgba(16, 185, 129, 0.05)' : 'white'};
  
  &:hover {
    border-color: ${props => props.$selected ? 'var(--primary)' : 'var(--primary-dark)'};
  }
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 0.85rem;
    color: var(--text-light);
    margin: 0;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const SelectControl = styled.div`
  display: flex;
  flex-direction: column;
  
  label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  select {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 1rem;
    
    &:focus {
      outline: none;
      border-color: var(--primary);
      box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.2);
    }
  }
`;

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const ReportTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
`;

const ReportDescription = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  color: var(--text-light);
`;

const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  background-color: ${props => 
    props.$type === 'completed' ? 'rgba(16, 185, 129, 0.1)' : 
    props.$type === 'in-progress' ? 'rgba(245, 158, 11, 0.1)' : 
    'rgba(99, 102, 241, 0.1)'
  };
  color: ${props => 
    props.$type === 'completed' ? 'var(--success)' : 
    props.$type === 'in-progress' ? 'var(--warning)' : 
    'var(--secondary)'
  };
`;

const ReportsList = styled.div`
  margin-bottom: 2rem;
`;

// Update the ecosystem report templates to align with the demo reports
const ecosystemReportTemplates = [
  {
    id: 'climate-risk-assessment',
    name: 'Climate Risk Assessment',
    description: 'Comprehensive assessment of climate risks for assets and portfolios with emphasis on ecosystem dependencies.',
    icon: <FiFileText size={24} style={{ color: 'var(--primary)' }} />,
    lastUpdated: '2023-09-05',
  },
  {
    id: 'carbon-footprint',
    name: 'Carbon Footprint Analysis',
    description: 'Assessment of carbon emissions including ecosystem carbon services and reduction opportunities.',
    icon: <FiFileText size={24} style={{ color: 'var(--primary)' }} />,
    lastUpdated: '2023-07-22',
  },
  {
    id: 'adaptation-strategy',
    name: 'Adaptation Strategy',
    description: 'Strategic recommendations for climate adaptation with natural capital solutions.',
    icon: <FiFileText size={24} style={{ color: 'var(--primary)' }} />,
    lastUpdated: '2023-08-10',
  },
  {
    id: 'resilience-report',
    name: 'Resilience Report',
    description: 'Analysis of climate resilience with actionable recommendations for ecosystem-based adaptation.',
    icon: <FiFileText size={24} style={{ color: 'var(--primary)' }} />,
    lastUpdated: '2023-09-18',
  },
  {
    id: 'tcfd-ecosystem',
    name: 'TCFD Ecosystem Services Report',
    description: 'TCFD-aligned disclosure of climate-related risks to ecosystem services in your value chain.',
    icon: <FiFileText size={24} style={{ color: 'var(--primary)' }} />,
    lastUpdated: '2023-03-08',
  }
];

// Add the ecosystem saved reports
const ecosystemSavedReports = [
  {
    id: 'eco-dep-q2-2023',
    name: 'Q2 2023 Ecosystem Dependencies Assessment',
    template: 'Ecosystem Dependency Analysis',
    dateCreated: '2023-06-30',
    status: 'completed',
    assetsCovered: 'All',
    createdBy: 'System',
  },
  {
    id: 'natural-cap-2023',
    name: 'Annual Natural Capital Valuation (2023)',
    template: 'Natural Capital Valuation',
    dateCreated: '2023-05-15',
    status: 'completed',
    assetsCovered: 'All',
    createdBy: 'System',
  },
  {
    id: 'water-risk-q1-2023',
    name: 'Water Resource Risk Analysis - Q1 2023',
    template: 'Ecosystem Risk Dashboard',
    dateCreated: '2023-04-12',
    status: 'completed',
    assetsCovered: 'Agricultural Land B, Manufacturing Hub D',
    createdBy: 'System',
  }
];

// Update the FeaturesAnnouncementBanner styling and content
const FeaturesAnnouncementBanner = styled.div`
  background-color: rgba(16, 185, 129, 0.05);
  border: 1px solid rgba(16, 185, 129, 0.2);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
  
  .badge {
    position: absolute;
    top: 10px;
    right: 50px;
    background-color: var(--primary);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 5;
  }
  
  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary);
  }
  
  p {
    color: var(--text);
    margin: 0.5rem 0 1rem;
    line-height: 1.6;
    font-size: 0.95rem;
  }
  
  .features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .feature-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    
    .icon {
      color: var(--primary);
      margin-top: 0.25rem;
      flex-shrink: 0;
    }
    
    .text {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  
  .highlight {
    font-weight: 600;
    color: var(--primary);
  }
`;

// Add styled components for the popup
const AnnouncementPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 1rem;
`;

const PopupContent = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 0;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: white;
  border: 1px solid var(--border);
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 10;
  
  &:hover {
    background-color: var(--background);
    color: var(--text);
  }
`;

const Reports = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [reportName, setReportName] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('all');
  const [selectedReportType, setSelectedReportType] = useState('all');
  // Add state for the announcement popup
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  
  // Combine original and ecosystem templates/reports
  const [templates, setTemplates] = useState([...reportTemplates, ...ecosystemReportTemplates]);
  const [reports, setReports] = useState([...savedReports, ...ecosystemSavedReports]);
  
  useEffect(() => {
    // Filter reports based on active tab
    if (activeTab === 'all') {
      setReports(savedReports);
    } else {
      setReports(savedReports.filter(report => report.status === activeTab));
    }

    // Show announcement popup every time the component mounts
    setShowAnnouncement(true);
  }, [activeTab]);
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTemplate(null);
    setReportName('');
  };

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
  };
  
  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
  };
  
  const handleCreateReport = () => {
    // In a real application, this would make an API call
    // For the demo, we'll just close the modal
    handleCloseModal();
    alert('Report creation would be initiated here. Since this is a mock frontend, no actual report will be created.');
  };
  
  // Get the status display elements
  const getStatusDisplay = (status) => {
    switch (status) {
      case 'completed':
        return <StatusBadge $status={status}><FiCheck /> Completed</StatusBadge>;
      case 'in-progress':
        return <StatusBadge $status={status}><FiLoader /> In Progress</StatusBadge>;
      default:
        return <StatusBadge $status="pending"><FiClock /> Pending</StatusBadge>;
    }
  };
  
  const getAssetName = (assetId) => {
    const asset = savedReports.find(report => report.id === assetId);
    return asset ? asset.name : 'Unknown Asset';
  };
  
  const filteredReports = reports.filter(report => {
    const matchesAsset = selectedAsset === 'all' || report.id === selectedAsset;
    const matchesType = selectedReportType === 'all' || report.type === selectedReportType;
    return matchesAsset && matchesType;
  });
  
  return (
    <ReportsContainer>
      {/* Add announcement popup */}
      {showAnnouncement && (
        <AnnouncementPopup>
          <PopupContent>
            <CloseButton onClick={() => setShowAnnouncement(false)}>
              <FiX />
            </CloseButton>
            <FeaturesAnnouncementBanner style={{ margin: 0, borderRadius: 0 }}>
              <div className="badge">Coming Soon</div>
              <h3>
                <FiAlertTriangle />
                AI-Powered Report Generation & Compliance Workflows
              </h3>
              <p>
                Our upcoming release will introduce <span className="highlight">advanced AI capabilities</span> to transform your reporting and compliance processes.
                The system will automatically analyze your asset data, generate customizable reports, and streamline disclosure workflows to drastically reduce your reporting burden.
              </p>
              <div className="features-list">
                <div className="feature-item">
                  <div className="icon"><FiCheck /></div>
                  <div className="text">AI-generated report content with customizable templates based on your specific asset data</div>
                </div>
                <div className="feature-item">
                  <div className="icon"><FiCheck /></div>
                  <div className="text">Automated TCFD & TNFD disclosure preparation with compliance gap analysis</div>
                </div>
                <div className="feature-item">
                  <div className="icon"><FiCheck /></div>
                  <div className="text">Smart data extraction from multiple sources with AI-driven insights</div>
                </div>
                <div className="feature-item">
                  <div className="icon"><FiCheck /></div>
                  <div className="text">One-click regulatory compliance reporting with continuous updates</div>
                </div>
              </div>
            </FeaturesAnnouncementBanner>
          </PopupContent>
        </AnnouncementPopup>
      )}

      <ReportsHeader>
        <div>
          <Title>Ecosystem Services Reports</Title>
          <Description>
            Review ecosystem service valuation reports, natural capital assessments, and climate-ecosystem analysis across your portfolio.
          </Description>
        </div>
        <ActionButton onClick={handleOpenModal}>
          <FiPlus /> Create New Report
        </ActionButton>
      </ReportsHeader>
      
      <ControlsContainer>
        <SelectControl>
          <label>Asset:</label>
          <select 
            value={selectedAsset} 
            onChange={(e) => setSelectedAsset(e.target.value)}
          >
            <option value="all">All Assets</option>
            <option value="1">Mountain Resort E</option>
            <option value="2">Oceanside Hotel Complex</option>
            <option value="3">Alpine Winery</option>
            <option value="4">Urban Office Complex</option>
          </select>
        </SelectControl>
        <SelectControl>
          <label>Report Type:</label>
          <select 
            value={selectedReportType} 
            onChange={(e) => setSelectedReportType(e.target.value)}
          >
            <option value="all">All Report Types</option>
            <option value="climate-risk-assessment">Climate Risk Assessment</option>
            <option value="carbon-footprint">Carbon Footprint Analysis</option>
            <option value="adaptation-strategy">Adaptation Strategy</option>
            <option value="resilience-report">Resilience Report</option>
            <option value="tcfd-ecosystem">TCFD Ecosystem Services Report</option>
          </select>
        </SelectControl>
      </ControlsContainer>
      
      <TabsContainer>
        <Tab 
          $active={activeTab === 'all'} 
          onClick={() => setActiveTab('all')}
        >
          All Reports
        </Tab>
        <Tab 
          $active={activeTab === 'completed'} 
          onClick={() => setActiveTab('completed')}
        >
          Completed
        </Tab>
        <Tab 
          $active={activeTab === 'in-progress'} 
          onClick={() => setActiveTab('in-progress')}
        >
          In Progress
        </Tab>
      </TabsContainer>
      
      {filteredReports.length > 0 ? (
        <ReportsGrid>
          {filteredReports.map((report) => (
            <ReportCard key={report.id}>
              <ReportIconHeader>
                <div className="icon">
                  <FiFileText size={24} style={{ color: 'var(--primary)' }} />
                </div>
                {getStatusDisplay(report.status)}
              </ReportIconHeader>
              <h3>{report.name}</h3>
              <ReportMeta>
                <MetaItem>
                  <FiCalendar />
                  {new Date(report.createdAt || report.dateCreated).toLocaleDateString()}
                </MetaItem>
                <MetaItem>
                  <FiFileText />
                  {report.template}
                </MetaItem>
              </ReportMeta>
              <ReportDescription>
                {report.description || `This report provides comprehensive insights and analysis based on the ${report.template} template.`}
              </ReportDescription>
              <ReportActions>
                <ReportButton $primary>View Report</ReportButton>
                <ReportButton>
                  <FiDownload /> Download
                </ReportButton>
              </ReportActions>
            </ReportCard>
          ))}
        </ReportsGrid>
      ) : (
        <EmptyState>
          <div className="icon"><FiFile /></div>
          <h3>No reports found</h3>
          <p>
            {activeTab === 'all' 
              ? 'You haven\'t created any reports yet.' 
              : `You don't have any ${activeTab} reports.`}
          </p>
          <ActionButton onClick={handleOpenModal}>
            <FiPlus /> Create New Report
          </ActionButton>
        </EmptyState>
      )}
      
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Create New Report</h2>
              <button onClick={handleCloseModal}>&times;</button>
            </ModalHeader>
            
            <FormGroup>
              <label htmlFor="reportName">Report Name</label>
              <input 
                type="text" 
                id="reportName" 
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                placeholder="Enter report name"
              />
            </FormGroup>
            
            <FormGroup>
              <label>Select Report Template</label>
              <TemplateGrid>
                {templates.map(template => (
                  <TemplateCard 
                    key={template.id}
                    $selected={selectedTemplate === template.id}
                    onClick={() => handleTemplateSelect(template.id)}
                  >
                    <h3>{template.name}</h3>
                    <p>{template.description}</p>
                  </TemplateCard>
                ))}
              </TemplateGrid>
            </FormGroup>
            
            <FormGroup>
              <label htmlFor="assets">Select Assets</label>
              <select id="assets" multiple disabled>
                <option>All assets would be selectable here</option>
              </select>
              <p style={{ fontSize: '0.85rem', color: 'var(--text-light)', marginTop: '0.5rem' }}>
                In this mock frontend, all assets will be included.
              </p>
            </FormGroup>
            
            <FormGroup>
              <label htmlFor="report-metrics">Metrics to Include:</label>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '0.9rem' }}>
                  <input type="checkbox" defaultChecked /> Financial Impact Assessment
                </label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '0.9rem' }}>
                  <input type="checkbox" defaultChecked /> Ecosystem Service Dependencies
                </label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '0.9rem' }}>
                  <input type="checkbox" defaultChecked /> Natural Capital Valuation
                </label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '0.9rem' }}>
                  <input type="checkbox" defaultChecked /> Climate Risk Exposure
                </label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '0.9rem' }}>
                  <input type="checkbox" /> Supply Chain Dependencies
                </label>
              </div>
            </FormGroup>
            
            <ModalActions>
              <ReportButton onClick={handleCloseModal}>
                Cancel
              </ReportButton>
              <ReportButton 
                $primary
                onClick={handleCreateReport}
                disabled={!reportName || !selectedTemplate}
              >
                Create Report
              </ReportButton>
            </ModalActions>
          </ModalContent>
        </Modal>
      )}
    </ReportsContainer>
  );
};

export default Reports; 