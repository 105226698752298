import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiBell, FiSettings, FiUser, FiLogOut, FiMenu } from 'react-icons/fi';
import NotificationsDropdown from '../notifications/NotificationsDropdown';
import notificationService from '../../utils/notificationService';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  right: 0;
  height: var(--header-height);
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 90;
  
  @media (max-width: 768px) {
    left: 0;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const HeaderIconButton = styled.button`
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light);
  cursor: pointer;
  position: relative;
  
  &:hover {
    background-color: var(--background);
  }
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--error);
`;

const UserMenu = styled.div`
  position: relative;
`;

const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
`;

const UserMenuDropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  z-index: 100;
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

const UserMenuHeader = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border);
`;

const UserName = styled.div`
  font-weight: 600;
`;

const UserEmail = styled.div`
  font-size: 0.8rem;
  color: var(--text-light);
`;

const UserMenuLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--text);
  text-decoration: none;
  
  &:hover {
    background-color: var(--background);
  }
`;

const UserMenuButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: var(--text);
  cursor: pointer;
  
  &:hover {
    background-color: var(--background);
  }
`;

const MobileMenuButton = styled.button`
  background: none;
  border: none;
  color: var(--text);
  cursor: pointer;
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const HeaderWithLogout = ({ style }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  
  // Mock user data
  const user = {
    name: 'John Doe',
    email: 'john@example.com',
    initials: 'JD'
  };
  
  useEffect(() => {
    // Fetch recent notifications for the dropdown
    const fetchData = async () => {
      try {
        const recentNotifications = await notificationService.fetchRecentNotifications();
        setNotifications(recentNotifications);
        
        const count = await notificationService.getUnreadCount();
        setUnreadCount(count);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };
    
    fetchData();
    
    // Set up interval to periodically update notifications (every 60 seconds)
    const intervalId = setInterval(fetchData, 60000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
    // Close notifications dropdown if open
    if (isNotificationsOpen) setIsNotificationsOpen(false);
  };
  
  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    // Close user menu if open
    if (isUserMenuOpen) setIsUserMenuOpen(false);
  };
  
  const handleMarkAsRead = async (id) => {
    try {
      await notificationService.markAsRead(id);
      
      // Update local state
      setNotifications(notifications.map(n => 
        n.id === id ? { ...n, unread: false } : n
      ));
      
      // Update unread count
      const count = await notificationService.getUnreadCount();
      setUnreadCount(count);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };
  
  const handleLogout = () => {
    // Since this is just a UI mockup, we'll just reset the menu state
    setIsUserMenuOpen(false);
    alert('Logout functionality would be implemented here.');
  };
  
  return (
    <HeaderContainer style={style}>
      <HeaderTitle>
        <MobileMenuButton>
          <FiMenu size={24} />
        </MobileMenuButton>
        Mandjet - Ecosystem Risk Foresight
      </HeaderTitle>
      
      <HeaderActions>
        <div style={{ position: 'relative' }}>
          <HeaderIconButton onClick={toggleNotifications}>
            <FiBell />
            {unreadCount > 0 && <NotificationBadge />}
          </HeaderIconButton>
          
          <NotificationsDropdown 
            isOpen={isNotificationsOpen}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
          />
        </div>
        
        <UserMenu>
          <UserAvatar onClick={toggleUserMenu}>
            {user.initials}
          </UserAvatar>
          
          <UserMenuDropdown $isOpen={isUserMenuOpen}>
            <UserMenuHeader>
              <UserName>{user.name}</UserName>
              <UserEmail>{user.email}</UserEmail>
            </UserMenuHeader>
            
            <UserMenuLink to="/settings">
              <FiSettings size={16} /> Settings
            </UserMenuLink>
            
            <UserMenuButton onClick={handleLogout}>
              <FiLogOut size={16} /> Logout
            </UserMenuButton>
          </UserMenuDropdown>
        </UserMenu>
      </HeaderActions>
    </HeaderContainer>
  );
};

export default HeaderWithLogout; 