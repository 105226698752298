import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import './App.css';
import styled from 'styled-components';

// Components
import Sidebar from './components/layout/Sidebar';
import HeaderWithLogout from './components/layout/HeaderWithLogout';
import Footer from './components/layout/Footer';

// Pages
import Dashboard from './pages/Dashboard';
import AssetList from './pages/AssetList';
import AssetDetail from './pages/AssetDetail';
import Reports from './pages/Reports';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import NotFound from './pages/NotFound';
import Simulations from './pages/Simulations';
import Notifications from './pages/Notifications';

// Theme
const theme = {
  colors: {
    primary: 'var(--primary)',
    secondary: 'var(--secondary)',
    background: 'var(--background)',
    text: 'var(--text)',
  }
};

// Add styled component for warning announcement bar
const WarningBar = styled.div`
  background-color: #ffedd5;
  border-bottom: 1px solid #f97316;
  color: #9a3412;
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  
  .main-warning {
    margin-bottom: 0.25rem;
  }
  
  .sub-warning {
    font-size: 0.8rem;
    opacity: 0.9;
  }
  
  .copyright {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.25rem;
  }
`;

/**
 * Main application component
 * 
 * Features:
 * - Responsive layout with sidebar and header
 * - Route management for all application pages
 * - Warning bar for demo information
 * - Notification system with:
 *   - Real-time notifications in the header
 *   - Dedicated notifications page
 *   - Different notification types (risk alerts, reports, analytics updates)
 *   - Read/unread status tracking
 */
function App() {
  // Track if we're on a page that should show header/sidebar
  const [isAppPage, setIsAppPage] = useState(true);

  // Add class names based on page type
  const appClassName = isAppPage ? 'app app-page' : 'app landing-page';

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={appClassName}>
          <Toaster position="top-right" />
          <ToastContainer position="top-right" autoClose={5000} />
          
          {/* Warning bar appears before everything else */}
          <WarningBar>
            <div className="main-warning">
              This is a demo and all of the numbers are completely made up and this is not meant to be accurate. This is not at all the full application which includes many variable adjustments and other modeling.
            </div>
            <div className="sub-warning">
              Species-specific risk pages are not added here. Please scroll down on each page to view all content and features.
            </div>
            <div className="copyright">
              Mandjet LLC Copyright 2025
            </div>
          </WarningBar>
          
          {isAppPage && (
            <HeaderWithLogout style={{ position: 'fixed', top: '64px', left: 0, right: 0, zIndex: 900 }} />
          )}
          
          <div className="main-content" style={{ paddingTop: "6rem" }}>
            {isAppPage && <Sidebar />}
            
            <div className="content-area">
              <Routes>
                {/* Main dashboard route */}
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                
                {/* Asset routes */}
                <Route path="/asset-list" element={<AssetList />} />
                <Route path="/assets" element={<Navigate to="/asset-list" replace />} />
                <Route path="/asset/:id" element={<AssetDetail />} />
                <Route path="/assets/:id" element={<AssetDetail />} />
                
                {/* Reports and Analytics */}
                <Route path="/reports" element={<Reports />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/simulations" element={<Simulations />} />
                
                {/* Notifications */}
                <Route path="/notifications" element={<Notifications />} />
                
                {/* Fallback route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          
          {!isAppPage && <Footer />}
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
