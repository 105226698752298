/**
 * This file contains all the mock data for the Mandjet frontend replica.
 * In a real application, this data would come from API calls to a backend service.
 */

// Mock User Data
export const currentUser = {
  id: 'user123',
  name: 'John Doe',
  email: 'john@example.com',
  company: 'Acme Corporation',
  role: 'Environmental Risk Analyst',
  createdAt: '2023-01-15T10:30:00Z',
  preferences: {
    darkMode: false,
    notifications: true,
    defaultDashboard: 'overview'
  }
};

// Mock Assets Data
export const assets = [
  {
    id: 1,
    name: 'Mountain Resort E',
    type: 'Hospitality',
    location: 'Rocky Mountains',
    value: 12000000,
    risk: 'high',
    riskScore: 68,
    status: 'warning',
    coordinates: { lat: 39.1911, lng: -106.8175 },
    description: 'Luxury mountain ski resort with restaurants, accommodations, and recreation facilities',
    details: {
      size: '15 acres',
      yearBuilt: 1995,
      lastRenovated: 2019,
      rooms: '45 guest rooms, 15 chalets',
      amenities: 'Restaurant, spa, ski storage',
      elevation: '8,000 ft above sea level'
    },
    ecosystemDependencies: [
      {
        name: "Alpine Snowpack",
        type: "Winter Recreation",
        risk: "critical",
        impactValue: "$4.8M/year",
        description: "32% reduction in snowpack depth projected by 2040 with 25-40 day reduction in ski season"
      },
      {
        name: "Mountain Watershed",
        type: "Water Supply",
        risk: "high",
        impactValue: "$1.2M/year",
        description: "Declining spring flow and snowmelt affecting water availability for resort operations"
      },
      {
        name: "Regional Food Systems",
        type: "Restaurant Supply",
        risk: "medium", 
        impactValue: "$820K/year",
        description: "Climate impacts on local agriculture affecting food quality and availability"
      }
    ]
  },
  {
    id: 2,
    name: 'Oceanside Hotel Complex',
    type: 'Hospitality',
    location: 'Florida Coast',
    value: 18500000,
    risk: 'critical',
    riskScore: 82,
    status: 'critical',
    coordinates: { lat: 25.7617, lng: -80.1918 },
    description: 'Beachfront hotel with restaurants, spa, and water activities',
    details: {
      size: '12 acres',
      yearBuilt: 2002,
      lastRenovated: 2018,
      rooms: '220 guest rooms, 15 luxury suites',
      amenities: 'Restaurants, spa, private beach access',
      elevation: '8 ft above sea level'
    },
    ecosystemDependencies: [
      {
        name: "Coral Reef Ecosystem",
        type: "Tourism & Recreation",
        risk: "critical",
        impactValue: "$3.2M/year",
        description: "Severe coral bleaching affecting 65% of reef structures, reducing tourism value"
      },
      {
        name: "Coastal Beach Systems",
        type: "Beach Tourism",
        risk: "critical",
        impactValue: "$5.6M/year",
        description: "Beach erosion from rising sea levels with loss of 28-35% of beach area by 2040"
      },
      {
        name: "Marine Fisheries",
        type: "Seafood Restaurant",
        risk: "high", 
        impactValue: "$1.5M/year",
        description: "Warming oceans and changing migration patterns affecting seafood availability"
      }
    ]
  },
  {
    id: 3,
    name: 'Alpine Winery',
    type: 'Agriculture',
    location: 'California',
    value: 8700000,
    risk: 'medium',
    riskScore: 56,
    status: 'warning',
    coordinates: { lat: 38.5025, lng: -122.2654 },
    description: 'Vineyard and wine production facility in mountainous terrain',
    details: {
      size: '85 acres',
      yearAcquired: 2005,
      primaryUse: 'Viticulture & Wine Production',
      soilType: 'Volcanic loam',
      waterSource: 'Local watershed and wells',
      production: '25,000 cases annually'
    },
    ecosystemDependencies: [
      {
        name: "Mediterranean Climate",
        type: "Grape Production",
        risk: "high",
        impactValue: "$2.7M/year",
        description: "Heat spikes during critical ripening periods affecting grape quality and yield"
      },
      {
        name: "Local Watershed",
        type: "Irrigation Water",
        risk: "critical",
        impactValue: "$1.8M/year",
        description: "Declining summer streamflow by 35-45% affecting water availability"
      },
      {
        name: "Pollinator Systems",
        type: "Vine Health",
        risk: "medium", 
        impactValue: "$620K/year",
        description: "Phenological mismatches between flowering and bee activity"
      }
    ]
  },
  {
    id: 4,
    name: 'Urban Office Complex',
    type: 'Commercial Real Estate',
    location: 'Chicago',
    value: 22500000,
    risk: 'medium',
    riskScore: 42,
    status: 'active',
    coordinates: { lat: 41.8781, lng: -87.6298 },
    description: 'Downtown multi-tenant office building with retail spaces',
    details: {
      size: '125,000 sq ft',
      yearBuilt: 2008,
      lastRenovated: 2020,
      occupancy: '85%',
      tenants: '12 corporate, 8 retail',
      certification: 'LEED Gold'
    },
    ecosystemDependencies: [
      {
        name: "Urban Tree Canopy",
        type: "Building Cooling",
        risk: "high",
        impactValue: "$380K/year",
        description: "Heat island intensification due to urban tree loss affecting cooling needs"
      },
      {
        name: "Regional Watershed",
        type: "Water & Energy",
        risk: "medium",
        impactValue: "$520K/year",
        description: "Reduced river flow affecting hydroelectric power generation and water supply"
      },
      {
        name: "Agricultural Systems",
        type: "Retail Supply Chain",
        risk: "high", 
        impactValue: "$910K/year",
        description: "Climate impacts on agricultural products affecting retail tenant supply chains"
      }
    ]
  }
];

// Mock Risk Assessment Data
export const riskAssessments = [
  {
    id: 101,
    assetId: 1, // Mountain Resort E
    date: '2023-09-15',
    score: 68,
    factors: {
      reducedSnowfall: 85,
      waterScarcity: 65,
      wildfire: 60,
      biodiversityLoss: 55,
      extremeWeather: 70
    },
    ecosystemImpacts: [
      { service: "Winter Recreation", score: 85, trend: "worsening" },
      { service: "Water Supply", score: 65, trend: "worsening" },
      { service: "Forest Health", score: 60, trend: "stable" }
    ],
    notes: 'Decreased snowfall patterns threatening winter season length. Water supply concerns increasing.'
  },
  {
    id: 102,
    assetId: 1, // Mountain Resort E
    date: '2023-06-20',
    score: 64,
    factors: {
      reducedSnowfall: 80,
      waterScarcity: 60,
      wildfire: 60,
      biodiversityLoss: 50,
      extremeWeather: 65
    },
    ecosystemImpacts: [
      { service: "Winter Recreation", score: 80, trend: "worsening" },
      { service: "Water Supply", score: 60, trend: "stable" },
      { service: "Forest Health", score: 55, trend: "stable" }
    ],
    notes: 'Summer assessment showing improved water conditions but continued snowfall concerns.'
  },
  {
    id: 201,
    assetId: 2, // Oceanside Hotel Complex
    date: '2023-11-05',
    score: 82,
    factors: {
      seaLevelRise: 85,
      coastalErosion: 90,
      extremeWeather: 80,
      oceanAcidification: 75,
      marineEcosystemDecline: 85
    },
    ecosystemImpacts: [
      { service: "Beach Tourism", score: 90, trend: "worsening" },
      { service: "Coral Reef Tourism", score: 85, trend: "worsening" },
      { service: "Seafood Supply", score: 75, trend: "worsening" }
    ],
    notes: 'Critical concerns for beach erosion and coral reef health. Recent storm damage accelerating issues.'
  },
  {
    id: 301,
    assetId: 3, // Alpine Winery
    date: '2023-09-15',
    score: 56,
    factors: {
      drought: 75,
      heatWaves: 70,
      waterScarcity: 80,
      phenologyShifts: 65,
      pestPressure: 50
    },
    ecosystemImpacts: [
      { service: "Grape Production", score: 70, trend: "worsening" },
      { service: "Water Supply", score: 80, trend: "worsening" },
      { service: "Pollination", score: 50, trend: "stable" }
    ],
    notes: 'Increasing heat and water stress affecting grape quality. Weather pattern unpredictability disrupting harvest timing.'
  },
  {
    id: 401,
    assetId: 4, // Urban Office Complex
    date: '2023-10-10',
    score: 42,
    factors: {
      urbanHeatIsland: 65,
      powerReliability: 45,
      waterAvailability: 40,
      stormwaterManagement: 55,
      supplyChainDisruption: 45
    },
    ecosystemImpacts: [
      { service: "Urban Cooling", score: 65, trend: "worsening" },
      { service: "Water Supply", score: 40, trend: "stable" },
      { service: "Stormwater Regulation", score: 55, trend: "worsening" }
    ],
    notes: 'Urban heat island effect intensifying, impacting cooling costs. Stormwater management concerns during heavy precipitation events.'
  },
  {
    id: 501,
    assetId: 5, // Urban Office Complex (Seattle)
    date: '2023-10-20',
    score: 52,
    factors: {
      urbanHeatIsland: 70,
      powerReliability: 50,
      waterAvailability: 45,
      stormwaterManagement: 60,
      supplyChainDisruption: 40
    },
    ecosystemImpacts: [
      { service: "Urban Cooling", score: 70, trend: "worsening" },
      { service: "Water Supply", score: 45, trend: "stable" },
      { service: "Stormwater Regulation", score: 60, trend: "worsening" }
    ],
    notes: 'Urban heat island effect intensifying, impacting cooling costs. Stormwater management concerns during heavy precipitation events.'
  }
];

// Mock Simulation Data
export const simulations = [
  {
    id: 'sim101',
    assetId: 1, // Mountain Resort E
    name: 'Winter Tourism Impact',
    date: '2023-09-25T14:30:00Z',
    scenario: 'Moderate Warming',
    timeHorizon: 2050,
    results: {
      riskScore: 78,
      financialImpact: {
        valueAtRisk: 5200000,
        annualLossExpectancy: 420000,
        adaptationCosts: 1800000,
        ecosystemServiceLoss: 3400000
      },
      keyImpacts: [
        { factor: 'Ski Season Length', value: '-32 days', impact: 'Critical' },
        { factor: 'Water Availability', value: '-35%', impact: 'High' },
        { factor: 'Natural Snowpack', value: '-45%', impact: 'Critical' },
        { factor: 'Food Supply Chain', value: '+28% cost', impact: 'Medium' }
      ],
      ecosystemServices: [
        { service: 'Winter Recreation', currentValue: '85% of optimal', projectedValue: '55% of optimal', financialImpact: '-$2.8M' },
        { service: 'Water Supply', currentValue: '90% of demand', projectedValue: '65% of demand', financialImpact: '-$1.2M' },
        { service: 'Food Production', currentValue: 'Stable', projectedValue: 'Disrupted', financialImpact: '-$0.8M' }
      ]
    }
  },
  {
    id: 'sim201',
    assetId: 2, // Oceanside Hotel Complex
    name: 'Coastal Impact Projection',
    date: '2023-11-10T09:15:00Z',
    scenario: 'High Emissions',
    timeHorizon: 2045,
    results: {
      riskScore: 88,
      financialImpact: {
        valueAtRisk: 7800000,
        annualLossExpectancy: 650000,
        adaptationCosts: 2900000,
        ecosystemServiceLoss: 5100000
      },
      keyImpacts: [
        { factor: 'Beach Erosion', value: '35% loss', impact: 'Critical' },
        { factor: 'Coral Reef Health', value: '65% decline', impact: 'Critical' },
        { factor: 'Storm Frequency', value: '+40%', impact: 'High' },
        { factor: 'Sea Level Rise', value: '+0.8m', impact: 'Critical' }
      ],
      ecosystemServices: [
        { service: 'Beach Tourism', currentValue: '90% of optimal', projectedValue: '45% of optimal', financialImpact: '-$3.2M' },
        { service: 'Coral Reef Tourism', currentValue: '75% of optimal', projectedValue: '30% of optimal', financialImpact: '-$1.9M' },
        { service: 'Coastal Protection', currentValue: 'Functioning', projectedValue: 'Severely Degraded', financialImpact: '-$1.2M' }
      ]
    }
  },
  {
    id: 'sim301',
    assetId: 3, // Alpine Winery
    name: 'Viticulture Climate Impact',
    date: '2023-09-30T11:20:00Z',
    scenario: 'Moderate Warming',
    timeHorizon: 2040,
    results: {
      riskScore: 68,
      financialImpact: {
        valueAtRisk: 3600000,
        annualLossExpectancy: 280000,
        adaptationCosts: 1500000,
        ecosystemServiceLoss: 2200000
      },
      keyImpacts: [
        { factor: 'Growing Season Temp', value: '+2.8°C', impact: 'High' },
        { factor: 'Water Availability', value: '-40%', impact: 'Critical' },
        { factor: 'Harvest Timing', value: '3 weeks earlier', impact: 'Medium' },
        { factor: 'Extreme Heat Days', value: '+15 days/year', impact: 'High' }
      ],
      ecosystemServices: [
        { service: 'Grape Production', currentValue: '95% quality', projectedValue: '70% quality', financialImpact: '-$1.4M' },
        { service: 'Water Supply', currentValue: 'Adequate', projectedValue: 'Severely Limited', financialImpact: '-$1.2M' },
        { service: 'Pollination', currentValue: 'Natural', projectedValue: 'Requires Intervention', financialImpact: '-$0.4M' }
      ]
    }
  },
  {
    id: 'sim401',
    assetId: 4, // Urban Office Complex
    name: 'Urban Climate Resilience',
    date: '2023-10-15T13:45:00Z',
    scenario: 'Business as Usual',
    timeHorizon: 2050,
    results: {
      riskScore: 58,
      financialImpact: {
        valueAtRisk: 2800000,
        annualLossExpectancy: 210000,
        adaptationCosts: 1200000,
        ecosystemServiceLoss: 1800000
      },
      keyImpacts: [
        { factor: 'Cooling Demand', value: '+35%', impact: 'High' },
        { factor: 'Urban Heat Island', value: '+3.2°C', impact: 'High' },
        { factor: 'Stormwater Runoff', value: '+25%', impact: 'Medium' },
        { factor: 'Energy Disruption', value: '3x frequency', impact: 'Medium' }
      ],
      ecosystemServices: [
        { service: 'Urban Cooling', currentValue: 'Functioning', projectedValue: 'Degraded', financialImpact: '-$0.8M' },
        { service: 'Stormwater Management', currentValue: 'Adequate', projectedValue: 'Stressed', financialImpact: '-$0.6M' },
        { service: 'Energy Supply', currentValue: 'Reliable', projectedValue: 'Periodic Disruption', financialImpact: '-$0.4M' }
      ]
    }
  },
  {
    id: 'sim501',
    assetId: 5, // Urban Office Complex (Seattle)
    name: 'Urban Climate Resilience',
    date: '2023-10-25T10:30:00Z',
    scenario: 'Moderate Warming',
    timeHorizon: 2045,
    results: {
      riskScore: 64,
      financialImpact: {
        valueAtRisk: 3200000,
        annualLossExpectancy: 240000,
        adaptationCosts: 1400000,
        ecosystemServiceLoss: 1600000
      },
      keyImpacts: [
        { factor: 'Cooling Demand', value: '+28%', impact: 'High' },
        { factor: 'Urban Heat Island', value: '+2.8°C', impact: 'High' },
        { factor: 'Stormwater Runoff', value: '+32%', impact: 'Medium' },
        { factor: 'Energy Disruption', value: '2x frequency', impact: 'Medium' }
      ],
      ecosystemServices: [
        { service: 'Urban Cooling', currentValue: 'Functioning', projectedValue: 'Degraded', financialImpact: '-$0.7M' },
        { service: 'Stormwater Management', currentValue: 'Adequate', projectedValue: 'Stressed', financialImpact: '-$0.5M' },
        { service: 'Energy Supply', currentValue: 'Reliable', projectedValue: 'Periodic Disruption', financialImpact: '-$0.4M' }
      ]
    }
  }
];

// Mock Report Templates
export const reportTemplates = [
  {
    id: 'climate-risk-assessment',
    name: 'Climate Risk Assessment',
    description: 'Comprehensive assessment of climate risks for assets and portfolios'
  },
  {
    id: 'carbon-footprint',
    name: 'Carbon Footprint Analysis',
    description: 'Assessment of carbon emissions and reduction opportunities'
  },
  {
    id: 'adaptation-strategy',
    name: 'Adaptation Strategy',
    description: 'Strategic recommendations for climate adaptation'
  },
  {
    id: 'resilience-report',
    name: 'Resilience Report',
    description: 'Analysis of climate resilience with actionable recommendations'
  }
];

// Mock Saved Reports
export const savedReports = [
  {
    id: 'report-001',
    name: 'Q2 2023 Portfolio Risk Assessment',
    template: 'climate-risk-assessment',
    createdAt: '2023-06-15T10:30:00Z',
    status: 'completed'
  },
  {
    id: 'report-002',
    name: 'Alpine Valley Carbon Analysis',
    template: 'carbon-footprint',
    createdAt: '2023-07-22T14:45:00Z',
    status: 'completed'
  },
  {
    id: 'report-003',
    name: 'Mountain Resort Adaptation Plan',
    template: 'adaptation-strategy',
    createdAt: '2023-08-10T09:15:00Z',
    status: 'in-progress'
  },
  {
    id: 'report-004',
    name: 'Annual Risk Summary 2023',
    template: 'climate-risk-assessment',
    createdAt: '2023-09-05T11:20:00Z',
    status: 'in-progress'
  },
  {
    id: 'report-005',
    name: 'Alpine Eco-Resort Resilience Assessment',
    template: 'resilience-report',
    createdAt: '2023-09-18T16:00:00Z',
    status: 'pending'
  }
];

// Mock Historical Climate Data
export const historicalClimateData = {
  temperature: {
    monthly: [
      { month: 'Jan', value: 12.3, anomaly: 1.2 },
      { month: 'Feb', value: 13.5, anomaly: 1.4 },
      { month: 'Mar', value: 15.8, anomaly: 1.7 },
      { month: 'Apr', value: 18.2, anomaly: 1.3 },
      { month: 'May', value: 21.5, anomaly: 1.5 },
      { month: 'Jun', value: 24.8, anomaly: 1.8 },
      { month: 'Jul', value: 26.2, anomaly: 2.0 },
      { month: 'Aug', value: 26.0, anomaly: 1.9 },
      { month: 'Sep', value: 23.5, anomaly: 1.6 },
      { month: 'Oct', value: 20.1, anomaly: 1.5 },
      { month: 'Nov', value: 15.9, anomaly: 1.3 },
      { month: 'Dec', value: 13.0, anomaly: 1.2 }
    ],
    annual: [
      { year: 2013, average: 18.2, anomaly: 0.6 },
      { year: 2014, average: 18.5, anomaly: 0.9 },
      { year: 2015, average: 18.9, anomaly: 1.3 },
      { year: 2016, average: 19.2, anomaly: 1.6 },
      { year: 2017, average: 19.0, anomaly: 1.4 },
      { year: 2018, average: 19.3, anomaly: 1.7 },
      { year: 2019, average: 19.5, anomaly: 1.9 },
      { year: 2020, average: 19.8, anomaly: 2.2 },
      { year: 2021, average: 19.6, anomaly: 2.0 },
      { year: 2022, average: 19.9, anomaly: 2.3 },
      { year: 2023, average: 20.2, anomaly: 2.6 }
    ]
  },
  precipitation: {
    monthly: [
      { month: 'Jan', value: 85, anomaly: -5 },
      { month: 'Feb', value: 75, anomaly: -10 },
      { month: 'Mar', value: 90, anomaly: 5 },
      { month: 'Apr', value: 110, anomaly: 15 },
      { month: 'May', value: 120, anomaly: 10 },
      { month: 'Jun', value: 70, anomaly: -15 },
      { month: 'Jul', value: 60, anomaly: -10 },
      { month: 'Aug', value: 55, anomaly: -15 },
      { month: 'Sep', value: 80, anomaly: 0 },
      { month: 'Oct', value: 95, anomaly: 5 },
      { month: 'Nov', value: 100, anomaly: 10 },
      { month: 'Dec', value: 90, anomaly: 0 }
    ],
    annual: [
      { year: 2013, total: 920, anomaly: -30 },
      { year: 2014, total: 890, anomaly: -60 },
      { year: 2015, total: 950, anomaly: 0 },
      { year: 2016, total: 980, anomaly: 30 },
      { year: 2017, total: 910, anomaly: -40 },
      { year: 2018, total: 870, anomaly: -80 },
      { year: 2019, total: 830, anomaly: -120 },
      { year: 2020, total: 940, anomaly: -10 },
      { year: 2021, total: 960, anomaly: 10 },
      { year: 2022, total: 880, anomaly: -70 },
      { year: 2023, total: 850, anomaly: -100 }
    ]
  },
  extremeEvents: {
    annual: [
      { year: 2013, heatWaveDays: 5, heavyRainDays: 8, droughtWeeks: 3 },
      { year: 2014, heatWaveDays: 7, heavyRainDays: 6, droughtWeeks: 4 },
      { year: 2015, heatWaveDays: 9, heavyRainDays: 9, droughtWeeks: 2 },
      { year: 2016, heatWaveDays: 12, heavyRainDays: 11, droughtWeeks: 1 },
      { year: 2017, heatWaveDays: 10, heavyRainDays: 7, droughtWeeks: 6 },
      { year: 2018, heatWaveDays: 15, heavyRainDays: 5, droughtWeeks: 8 },
      { year: 2019, heatWaveDays: 18, heavyRainDays: 4, droughtWeeks: 9 },
      { year: 2020, heatWaveDays: 14, heavyRainDays: 10, droughtWeeks: 4 },
      { year: 2021, heatWaveDays: 20, heavyRainDays: 12, droughtWeeks: 3 },
      { year: 2022, heatWaveDays: 22, heavyRainDays: 8, droughtWeeks: 7 },
      { year: 2023, heatWaveDays: 25, heavyRainDays: 9, droughtWeeks: 6 }
    ]
  }
};

// Utility functions for accessing mock data
export const getAssetById = (id) => {
  console.log('getAssetById called with:', id, 'Type:', typeof id);
  
  // Parse the id to a number if it's a string
  const numericId = typeof id === 'string' ? parseInt(id, 10) : id;
  console.log('Looking for asset with numericId:', numericId);
  
  // Find asset and log result for debugging
  const foundAsset = assets.find(asset => asset.id === numericId);
  console.log('Found asset:', foundAsset ? foundAsset.name : 'none');
  
  return foundAsset;
};

export const getRiskAssessmentsByAssetId = (assetId) => {
  // Parse the id to a number if it's a string
  const numericId = typeof assetId === 'string' ? parseInt(assetId, 10) : assetId;
  return riskAssessments.filter(assessment => assessment.assetId === numericId);
};

export const getSimulationsByAssetId = (assetId) => {
  // Parse the id to a number if it's a string
  const numericId = typeof assetId === 'string' ? parseInt(assetId, 10) : assetId;
  return simulations.filter(simulation => simulation.assetId === numericId);
}; 