/**
 * Notification Service
 * 
 * This service handles the notification system for the application,
 * including fetching, marking as read, and managing notifications.
 */

// Mock data for notifications
const mockNotifications = [
  {
    id: 1,
    type: 'risk',
    title: 'URGENT: Critical Heatwave Alert',
    message: '🔥 CRITICAL: Alpine Winery experiencing extreme heatwave (42°C) severely affecting pollinator activity and grape ripening. Immediate action required!',
    time: 'just now',
    unread: true,
    asset: 'Alpine Winery',
    priority: 'critical',
    timestamp: new Date()
  },
  {
    id: 2,
    type: 'risk',
    title: 'URGENT: Pollinator Collapse Warning',
    message: '⚠️ URGENT: Severe decline in bee activity at Alpine Winery (85% reduction). Critical impact on vine health and yield expected within 7 days.',
    time: '10 minutes ago',
    unread: true,
    asset: 'Alpine Winery',
    priority: 'critical',
    timestamp: new Date(Date.now() - 10 * 60 * 1000)
  },
  {
    id: 3,
    type: 'risk',
    title: 'High Risk Alert',
    message: 'Mountain Resort E has been flagged for high risk due to recent assessment.',
    time: '1 hour ago',
    unread: true,
    asset: 'Mountain Resort E',
    priority: 'high',
    timestamp: new Date(Date.now() - 60 * 60 * 1000)
  },
  {
    id: 4,
    type: 'report',
    title: 'Report Generated',
    message: 'Quarterly risk assessment report for Oceanside Hotel Complex is now available.',
    time: '2 hours ago',
    unread: true,
    asset: 'Oceanside Hotel Complex',
    priority: 'medium',
    timestamp: new Date(Date.now() - 2 * 60 * 60 * 1000)
  },
  {
    id: 5,
    type: 'analytics',
    title: 'Climate Impact Update',
    message: 'New climate projections available for Alpine Winery region. Increased risk of heat stress on grape varieties.',
    time: '1 day ago',
    unread: false,
    asset: 'Alpine Winery',
    priority: 'medium',
    timestamp: new Date(Date.now() - 24 * 60 * 60 * 1000)
  },
  {
    id: 6,
    type: 'risk',
    title: 'Critical Risk Alert',
    message: 'Urban Office Complex faces critical flooding risk based on updated models.',
    time: '2 days ago',
    unread: false,
    asset: 'Urban Office Complex',
    priority: 'critical',
    timestamp: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
  },
  {
    id: 7,
    type: 'risk',
    title: 'Water Stress Warning',
    message: 'Declining watershed health affecting irrigation capacity at Alpine Winery. Potential impact on crop resilience during heatwave.',
    time: '3 days ago',
    unread: false,
    asset: 'Alpine Winery',
    priority: 'high',
    timestamp: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
  },
  {
    id: 8,
    type: 'risk',
    title: 'Early Snowmelt Warning',
    message: 'Accelerated spring snowmelt affecting water availability for Mountain Resort E operations.',
    time: '4 days ago',
    unread: false,
    asset: 'Mountain Resort E',
    priority: 'medium',
    timestamp: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000)
  },
  {
    id: 9,
    type: 'risk',
    title: 'Beach Erosion Alert',
    message: 'Oceanside Hotel Complex experiencing accelerated beach erosion due to recent storm surge events.',
    time: '5 days ago',
    unread: false,
    asset: 'Oceanside Hotel Complex',
    priority: 'high',
    timestamp: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
  },
  {
    id: 10,
    type: 'analytics',
    title: 'New Data Available',
    message: 'Historical climate data updated for all your assets.',
    time: '1 week ago',
    unread: false,
    asset: 'All Assets',
    priority: 'low',
    timestamp: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  },
];

/**
 * Format relative time for notifications
 * @param {Date} date - The date to format
 * @returns {string} Formatted relative time string
 */
const getRelativeTime = (date) => {
  const now = new Date();
  const diffMs = now - date;
  const diffSec = Math.floor(diffMs / 1000);
  const diffMin = Math.floor(diffSec / 60);
  const diffHour = Math.floor(diffMin / 60);
  const diffDay = Math.floor(diffHour / 24);
  const diffWeek = Math.floor(diffDay / 7);
  const diffMonth = Math.floor(diffDay / 30);
  
  if (diffSec < 60) return 'just now';
  if (diffMin < 60) return `${diffMin} minute${diffMin !== 1 ? 's' : ''} ago`;
  if (diffHour < 24) return `${diffHour} hour${diffHour !== 1 ? 's' : ''} ago`;
  if (diffDay < 7) return `${diffDay} day${diffDay !== 1 ? 's' : ''} ago`;
  if (diffWeek < 4) return `${diffWeek} week${diffWeek !== 1 ? 's' : ''} ago`;
  if (diffMonth < 12) return `${diffMonth} month${diffMonth !== 1 ? 's' : ''} ago`;
  
  return `${Math.floor(diffMonth / 12)} year${Math.floor(diffMonth / 12) !== 1 ? 's' : ''} ago`;
};

/**
 * Fetch all notifications
 * @returns {Promise<Array>} Promise that resolves to an array of notifications
 */
export const fetchNotifications = () => {
  // In a real app, this would be an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      // Update the relative times before returning
      const notificationsWithUpdatedTimes = mockNotifications.map(notification => ({
        ...notification,
        time: getRelativeTime(notification.timestamp)
      }));
      
      resolve(notificationsWithUpdatedTimes);
    }, 500);
  });
};

/**
 * Fetch recent notifications for dropdown
 * @param {number} limit - Maximum number of notifications to return
 * @returns {Promise<Array>} Promise that resolves to an array of recent notifications
 */
export const fetchRecentNotifications = (limit = 5) => {
  // In a real app, this would be an API call with a limit parameter
  return new Promise((resolve) => {
    setTimeout(() => {
      // Sort by timestamp (most recent first) and limit the results
      const sorted = [...mockNotifications]
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, limit)
        .map(notification => ({
          ...notification,
          time: getRelativeTime(notification.timestamp)
        }));
      
      resolve(sorted);
    }, 300);
  });
};

/**
 * Mark a notification as read
 * @param {number} id - ID of the notification to mark as read
 * @returns {Promise<boolean>} Promise that resolves to success status
 */
export const markAsRead = (id) => {
  // In a real app, this would be an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = mockNotifications.findIndex(n => n.id === id);
      if (index !== -1) {
        mockNotifications[index].unread = false;
        resolve(true);
      } else {
        resolve(false);
      }
    }, 200);
  });
};

/**
 * Mark all notifications as read
 * @returns {Promise<boolean>} Promise that resolves to success status
 */
export const markAllAsRead = () => {
  // In a real app, this would be an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      mockNotifications.forEach(notification => {
        notification.unread = false;
      });
      resolve(true);
    }, 300);
  });
};

/**
 * Dismiss a notification
 * @param {number} id - ID of the notification to dismiss
 * @returns {Promise<boolean>} Promise that resolves to success status
 */
export const dismissNotification = (id) => {
  // In a real app, this would be an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = mockNotifications.findIndex(n => n.id === id);
      if (index !== -1) {
        mockNotifications.splice(index, 1);
        resolve(true);
      } else {
        resolve(false);
      }
    }, 200);
  });
};

/**
 * Get the count of unread notifications
 * @returns {Promise<number>} Promise that resolves to the count of unread notifications
 */
export const getUnreadCount = () => {
  // In a real app, this would be a more efficient API call
  return new Promise((resolve) => {
    setTimeout(() => {
      const count = mockNotifications.filter(n => n.unread).length;
      resolve(count);
    }, 100);
  });
};

/**
 * Create a new notification (for testing and demo purposes)
 * @param {Object} notification - The notification to create
 * @returns {Promise<Object>} Promise that resolves to the created notification
 */
export const createNotification = (notification) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newNotification = {
        id: mockNotifications.length + 1,
        timestamp: new Date(),
        time: 'just now',
        unread: true,
        ...notification
      };
      
      mockNotifications.unshift(newNotification);
      resolve(newNotification);
    }, 200);
  });
};

export default {
  fetchNotifications,
  fetchRecentNotifications,
  markAsRead,
  markAllAsRead,
  dismissNotification,
  getUnreadCount,
  createNotification
}; 