import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { 
  FiAlertTriangle, 
  FiBarChart2, 
  FiActivity, 
  FiTrendingUp, 
  FiTrendingDown, 
  FiDollarSign, 
  FiMapPin, 
  FiCalendar,
  FiArrowRight,
  FiShield
} from 'react-icons/fi';
import { assets, riskAssessments, simulations } from '../data/mockData';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Styled components
const DashboardContainer = styled.div`
  padding: 1rem 0;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const DashboardTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const DashboardDescription = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  
  .value {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${props => props.color || 'inherit'};
  }
  
  .label {
    font-size: 0.9rem;
    color: var(--text-light);
  }
  
  .trend {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    
    &.up {
      color: var(--error);
    }
    
    &.down {
      color: var(--success);
    }
  }
`;

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ChartCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  
  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .subtitle {
    font-size: 0.9rem;
    color: var(--text-light);
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
`;

const ViewAllLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--primary);
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const AssetRiskGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const AssetRiskCard = styled(Link)`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  text-decoration: none;
  color: var(--text);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .meta {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .meta svg {
    color: var(--text-light);
    flex-shrink: 0;
  }
  
  .description {
    font-size: 0.85rem;
    margin-bottom: 0.75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .financial-impact {
    background-color: rgba(239, 68, 68, 0.05);
    border-radius: 0.375rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    
    .impact-title {
      font-size: 0.8rem;
      font-weight: 600;
      color: var(--error);
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    .impact-value {
      font-size: 1.1rem;
      font-weight: 700;
    }
    
    .impact-detail {
      font-size: 0.8rem;
      color: var(--text-light);
      margin-top: 0.25rem;
    }
  }
  
  .risk-indicator {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    
    .risk-bar {
      flex: 1;
      height: 8px;
      background-color: #e5e7eb;
      border-radius: 4px;
      overflow: hidden;
      
      .risk-level {
        height: 100%;
        border-radius: 4px;
      }
    }
    
    .risk-score {
      font-weight: 600;
      margin-left: 1rem;
    }
  }
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${props => 
    props.$status === 'active' ? 'var(--success)' : 
    props.$status === 'warning' ? 'var(--warning)' : 
    props.$status === 'critical' ? 'var(--error)' : 
    'var(--text-light)'
  };
`;

const CategoryLabel = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  background-color: ${props => 
    props.$type === 'Property' ? 'rgba(59, 130, 246, 0.1)' : 
    props.$type === 'Land' ? 'rgba(16, 185, 129, 0.1)' : 
    props.$type === 'Infrastructure' ? 'rgba(245, 158, 11, 0.1)' :
    props.$type === 'Facility' ? 'rgba(139, 92, 246, 0.1)' :
    'rgba(107, 114, 128, 0.1)'
  };
  color: ${props => 
    props.$type === 'Property' ? '#3b82f6' : 
    props.$type === 'Land' ? '#10b981' : 
    props.$type === 'Infrastructure' ? '#f59e0b' :
    props.$type === 'Facility' ? '#8b5cf6' :
    '#6b7280'
  };
`;

const RiskBadge = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: ${props => 
    props.$level === 'high' ? 'rgba(239, 68, 68, 0.1)' : 
    props.$level === 'medium' ? 'rgba(245, 158, 11, 0.1)' : 
    'rgba(16, 185, 129, 0.1)'
  };
  color: ${props => 
    props.$level === 'high' ? 'var(--error)' : 
    props.$level === 'medium' ? 'var(--warning)' : 
    'var(--success)'
  };
`;

const FinancialImpactTable = styled.div`
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid var(--border);
  
  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 0.75rem 1rem;
      text-align: left;
    }
    
    th {
      font-weight: 600;
      font-size: 0.9rem;
      background-color: var(--background);
      border-bottom: 1px solid var(--border);
    }
    
    td {
      font-size: 0.9rem;
      border-bottom: 1px solid var(--border);
    }
    
    tr:last-child td {
      border-bottom: none;
    }
    
    .value-cell {
      font-weight: 600;
    }
    
    .risk-cell {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dashboard = () => {
  // State to track dashboard data
  const [portfolioStats, setPortfolioStats] = useState(null);
  const [financialImpacts, setFinancialImpacts] = useState(null);
  
  // Calculate portfolio stats on component mount
  useEffect(() => {
    // Calculate portfolio statistics
    if (assets.length > 0) {
      // Count ecosystem dependencies by risk level
      const criticalDependencies = assets.reduce((count, asset) => {
        return count + (asset.ecosystemDependencies ? 
          asset.ecosystemDependencies.filter(dep => dep.risk === 'critical').length : 0);
      }, 0);
      
      // Calculate total ecosystem service value at risk
      const ecosystemServicesAtRisk = assets.reduce((total, asset) => {
        if (asset.ecosystemDependencies) {
          return total + asset.ecosystemDependencies.reduce((assetTotal, dep) => {
            const valueMatch = dep.impactValue.match(/\$([0-9.]+)([KM])/);
            if (valueMatch) {
              const value = parseFloat(valueMatch[1]);
              const multiplier = valueMatch[2] === 'M' ? 1000000 : 
                               valueMatch[2] === 'K' ? 1000 : 1;
              return assetTotal + (value * multiplier);
            }
            return assetTotal;
          }, 0);
        }
        return total;
      }, 0);
      
      // Filter assets by risk level for the portfolio stats
      const highRiskAssets = assets.filter(asset => asset.risk === 'high' || asset.risk === 'critical');
      const mediumRiskAssets = assets.filter(asset => asset.risk === 'medium');
      const lowRiskAssets = assets.filter(asset => asset.risk === 'low');
      
      // Calculate portfolio value
      const totalValue = assets.reduce((sum, asset) => sum + asset.value, 0);
      
      // Calculate value at risk
      const valueAtRisk = assets.reduce((sum, asset) => {
        return sum + getFinancialImpactEstimate(asset);
      }, 0);
      
      // Calculate annual loss expectancy (simplified)
      const annualLossExpectancy = Math.round(valueAtRisk * 0.08);
      
      // Calculate adaptation cost estimate
      const adaptationCostEstimate = assets.reduce((sum, asset) => {
        return sum + getAdaptationCostEstimate(asset);
      }, 0);
      
      // Update portfolio stats
      setPortfolioStats({
        totalAssets: assets.length,
        totalValue,
        highRiskAssets: highRiskAssets.length,
        mediumRiskAssets: mediumRiskAssets.length,
        lowRiskAssets: lowRiskAssets.length,
        avgRiskScore: Math.round(assets.reduce((sum, asset) => sum + asset.riskScore, 0) / assets.length),
        valueAtRisk,
        annualLossExpectancy,
        adaptationCostEstimate,
        insurancePremiumIncrease: 18,
        highRiskAssetsValue: highRiskAssets.reduce((sum, asset) => sum + asset.value, 0),
        ecosystemServicesAtRisk,
        criticalDependencies
      });
    }
  }, [assets]);
  
  // Financial impact projection chart data
  const financialImpactProjectionData = {
    labels: ['2023', '2025', '2030', '2035', '2040', '2050'],
    datasets: [
      {
        label: 'Value at Risk',
        data: [15, 17, 22, 28, 35, 45],
        borderColor: '#ef4444',
        backgroundColor: 'rgba(239, 68, 68, 0.1)',
        tension: 0.3,
        fill: true
      },
      {
        label: 'Total Service Value',
        data: [100, 98, 95, 92, 88, 85],
        borderColor: '#10b981',
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        tension: 0.3,
        fill: true
      }
    ]
  };
  
  // Risk distribution chart data
  const riskDistributionData = {
    labels: ['High Risk', 'Medium Risk', 'Low Risk'],
    datasets: [
      {
        data: portfolioStats ? [portfolioStats.highRiskAssets, portfolioStats.mediumRiskAssets, portfolioStats.lowRiskAssets] : [0, 0, 0],
        backgroundColor: ['#ef4444', '#f59e0b', '#10b981'],
        borderWidth: 0,
      }
    ]
  };
  
  // Financial impact by asset type chart
  const financialImpactByTypeData = {
    labels: ['Property', 'Land', 'Infrastructure', 'Facility'],
    datasets: [
      {
        label: 'Asset Value (Millions)',
        data: financialImpacts ? [
          financialImpacts.propertyValue / 1000000, 
          financialImpacts.landValue / 1000000,
          financialImpacts.infrastructureValue / 1000000,
          financialImpacts.facilityValue / 1000000
        ] : [0, 0, 0, 0],
        backgroundColor: '#3b82f6',
        yAxisID: 'y'
      },
      {
        label: 'Risk Score',
        data: financialImpacts ? [
          financialImpacts.propertyRisk,
          financialImpacts.landRisk,
          financialImpacts.infrastructureRisk,
          financialImpacts.facilityRisk
        ] : [0, 0, 0, 0],
        backgroundColor: '#ef4444',
        yAxisID: 'y1'
      }
    ]
  };
  
  // Get financial impact estimate for an asset
  const getFinancialImpactEstimate = (asset) => {
    // Simple calculation based on asset value and risk score
    const impactPercent = asset.riskScore / 100;
    return Math.round(asset.value * impactPercent);
  };
  
  // Get adaptation cost estimate for an asset
  const getAdaptationCostEstimate = (asset) => {
    // Simple calculation for demonstration
    return Math.round(asset.value * 0.05);
  };
  
  return (
    <DashboardContainer>
      <DashboardHeader>
        <div>
          <DashboardTitle>Ecosystem Services Risk Dashboard</DashboardTitle>
          <DashboardDescription>
            Monitor climate-related risks to ecosystem services that your assets depend on, track natural capital metrics, and identify opportunities for building resilience.
          </DashboardDescription>
        </div>
      </DashboardHeader>
      
      {portfolioStats && (
        <StatsGrid>
          <StatCard>
            <div className="value">
              <FiBarChart2 />
              {portfolioStats.totalAssets}
            </div>
            <div className="label">Total Assets</div>
          </StatCard>
          
          <StatCard>
            <div className="value">
              <FiDollarSign />
              ${(portfolioStats.totalValue / 1000000).toFixed(1)}M
            </div>
            <div className="label">Total Portfolio Value</div>
          </StatCard>
          
          <StatCard color="#ef4444">
            <div className="value">
              <FiAlertTriangle />
              ${(portfolioStats.ecosystemServicesAtRisk / 1000000).toFixed(1)}M
            </div>
            <div className="label">Ecosystem Services at Risk</div>
            <div className="trend up">
              <FiTrendingUp /> +12.4% from last year
            </div>
          </StatCard>
          
          <StatCard color="#f59e0b">
            <div className="value">
              <FiActivity />
              {portfolioStats.criticalDependencies}
            </div>
            <div className="label">Critical Ecosystem Dependencies</div>
            <div className="trend up">
              <FiTrendingUp /> +3 from last assessment
            </div>
          </StatCard>
        </StatsGrid>
      )}
      
      <ChartGrid>
        <ChartCard>
          <h2>
            Ecosystem Service Impact Projection
            <ViewAllLink to="/simulations">View Simulations <FiArrowRight size={14} /></ViewAllLink>
          </h2>
          <div className="subtitle">Projected ecosystem service degradation and financial impacts over time</div>
          
          <Line 
            data={financialImpactProjectionData} 
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `${context.dataset.label}: ${context.raw}% of service value`;
                    }
                  }
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: '% of Ecosystem Service Value'
                  },
                  ticks: {
                    callback: function(value) {
                      return value + '%';
                    }
                  }
                }
              }
            }} 
          />
        </ChartCard>
        
        <ChartCard>
          <h2>Ecosystem Dependency Risk</h2>
          
          <div style={{ maxHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Doughnut 
              data={{
                labels: ['Critical', 'High', 'Medium', 'Low'],
                datasets: [
                  {
                    data: [4, 8, 7, 5],
                    backgroundColor: ['#ef4444', '#f59e0b', '#3b82f6', '#10b981'],
                    borderWidth: 0
                  }
                ]
              }} 
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        const label = context.label;
                        const value = context.raw;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label} Risk: ${value} dependencies (${percentage}%)`;
                      }
                    }
                  }
                },
                cutout: '60%'
              }} 
            />
          </div>
          <div className="subtitle" style={{ textAlign: 'center', marginTop: '10px', fontSize: '0.85rem', color: 'var(--text-secondary)' }}>
            Distribution of risk categories across 24 identified ecosystem dependencies
          </div>
        </ChartCard>
      </ChartGrid>
      
      <ChartGrid>
        <ChartCard>
          <h2>Ecosystem Service Value by Type</h2>
          <div className="subtitle">Based on natural capital economic valuation methods</div>
          
          <Bar 
            data={{
              labels: [
                'Provisioning (Food, Water)', 
                'Regulating (Climate, Water)', 
                'Cultural (Tourism, Recreation)', 
                'Supporting (Habitat, Biodiversity)'
              ],
              datasets: [
                {
                  label: 'Annual Value (Millions)',
                  data: [4.2, 6.8, 5.1, 3.5],
                  backgroundColor: '#3b82f6',
                  yAxisID: 'y'
                },
                {
                  label: 'Risk Score',
                  data: [65, 72, 58, 48],
                  backgroundColor: '#ef4444',
                  yAxisID: 'y1'
                }
              ]
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      if (context.dataset.label === 'Annual Value (Millions)') {
                        return `${context.dataset.label}: $${context.raw.toFixed(1)}M`;
                      }
                      return `${context.dataset.label}: ${context.raw}`;
                    }
                  }
                }
              },
              scales: {
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  title: {
                    display: true,
                    text: 'Annual Value (Millions)'
                  },
                  ticks: {
                    callback: function(value) {
                      return '$' + value + 'M';
                    }
                  }
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  title: {
                    display: true,
                    text: 'Risk Score'
                  },
                  min: 0,
                  max: 100,
                  grid: {
                    drawOnChartArea: false
                  }
                }
              }
            }}
          />
        </ChartCard>
        
        <ChartCard>
          <h2>Ecosystem Service Impact Summary</h2>
          
          <FinancialImpactTable>
            <table>
              <thead>
                <tr>
                  <th>Ecosystem Service</th>
                  <th>Value at Risk</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Alpine Snowpack (Winter Recreation)</td>
                  <td className="value-cell">$4.8M/year</td>
                </tr>
                <tr>
                  <td>Coastal Beach Systems (Tourism)</td>
                  <td className="value-cell">$5.6M/year</td>
                </tr>
                <tr>
                  <td>Coral Reef Ecosystems (Tourism)</td>
                  <td className="value-cell">$3.2M/year</td>
                </tr>
                <tr>
                  <td>Vineyard Climate (Grape Production)</td>
                  <td className="value-cell">$2.7M/year</td>
                </tr>
                <tr>
                  <td>Local Watershed (Irrigation)</td>
                  <td className="value-cell">$1.8M/year</td>
                </tr>
                <tr>
                  <td>Mountain Watershed (Water Supply)</td>
                  <td className="value-cell">$1.2M/year</td>
                </tr>
                <tr>
                  <td>Marine Fisheries (Restaurant Supply)</td>
                  <td className="value-cell">$1.5M/year</td>
                </tr>
                <tr>
                  <td>Urban Tree Canopy (Building Cooling)</td>
                  <td className="value-cell">$0.9M/year</td>
                </tr>
              </tbody>
            </table>
          </FinancialImpactTable>
        </ChartCard>
      </ChartGrid>
      
      <SectionTitle>
        High-Risk Assets
        <ViewAllLink to="/asset-list">View All Assets <FiArrowRight size={14} /></ViewAllLink>
      </SectionTitle>
      
      <AssetRiskGrid>
        {assets
          .filter(asset => asset.id !== 5)
          .sort((a, b) => {
            if (a.name === 'Urban Office Complex') return -1;
            if (b.name === 'Urban Office Complex') return 1;
            return b.riskScore - a.riskScore;
          })
          .slice(0, 4)
          .map(asset => {
            // Get the most critical ecosystem dependency
            const criticalDependency = asset.ecosystemDependencies ? 
              [...asset.ecosystemDependencies].sort((a, b) => {
                const riskOrder = { 'critical': 3, 'high': 2, 'medium': 1, 'low': 0 };
                return riskOrder[b.risk] - riskOrder[a.risk];
              })[0] : null;
            
            console.log('Rendering asset card with ID:', asset.id, 'Name:', asset.name);
            
            return (
              <AssetRiskCard key={asset.id} to={`/asset/${asset.id}`}>
                <h3>
                  {asset.name}
                  <RiskBadge risk={asset.risk}>{asset.risk.charAt(0).toUpperCase() + asset.risk.slice(1)}</RiskBadge>
                </h3>
                <div className="meta">
                  <FiMapPin size={14} /> {asset.location}
                </div>
                <div className="meta">
                  <FiDollarSign size={14} /> ${(asset.value / 1000000).toFixed(1)}M
                </div>
                <div className="description">{asset.description}</div>
                
                {criticalDependency && (
                  <div className="financial-impact">
                    <div className="impact-title">
                      <FiAlertTriangle size={14} /> Top Ecosystem Dependency at Risk
                    </div>
                    <div className="impact-value">{criticalDependency.name}</div>
                    <div className="impact-detail">{criticalDependency.impactValue} | {criticalDependency.type}</div>
                  </div>
                )}
                
                <div className="risk-indicator">
                  <div className="risk-label">Risk Score</div>
                  <div className="risk-score">{asset.riskScore}</div>
                  <div className="risk-bar-container">
                    <div 
                      className="risk-bar" 
                      style={{ 
                        width: `${asset.riskScore}%`,
                        backgroundColor: asset.riskScore > 70 ? 'var(--error)' : 
                                        asset.riskScore > 50 ? 'var(--warning)' : 
                                        'var(--success)'
                      }}
                    ></div>
                  </div>
                </div>
              </AssetRiskCard>
            );
          })
        }
      </AssetRiskGrid>
    </DashboardContainer>
  );
};

export default Dashboard; 