// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getFirestore, 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  addDoc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  onSnapshot, 
  serverTimestamp
} from 'firebase/firestore';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  signInAnonymously,
  updateProfile,
  onAuthStateChanged as firebaseOnAuthStateChanged
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBINipDYIhPUx6NkjATFGX91ZrhajujF_I",
  authDomain: "mandjet-f8527.firebaseapp.com",
  projectId: "mandjet-f8527",
  storageBucket: "mandjet-f8527.firebasestorage.app",
  messagingSenderId: "889373009903",
  appId: "1:889373009903:web:8f73dce8c2f8bea9a9781a",
  measurementId: "G-CJNJPX9HEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// Export Firebase service objects
export { app, db, auth, functions, storage, analytics };

// Export onAuthStateChanged for convenience
export const onAuthStateChanged = (observer) => {
  return firebaseOnAuthStateChanged(auth, observer);
};

// Get current user ID
export const getCurrentUserId = () => {
  return auth.currentUser?.uid;
};

// Utility function to check authentication
export const checkAuthenticated = () => {
  return !!auth.currentUser;
};

// Sign in with email and password
export const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

// Sign out
export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    return true;
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

// Create a new user with email and password
export const createUserWithEmail = async (email, password, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    if (displayName) {
      await updateProfile(userCredential.user, { displayName });
    }
    
    return userCredential.user;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

// Export default for easier importing
export default {
  app,
  db,
  auth,
  functions,
  storage,
  analytics,
  signInWithEmail,
  signOut,
  createUserWithEmail,
  onAuthStateChanged,
  getCurrentUserId,
  checkAuthenticated
}; 