import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiBell, FiAlertTriangle, FiBarChart2, FiFile, FiCheck, FiClock, FiFilter, FiX } from 'react-icons/fi';
import notificationService from '../utils/notificationService';

const PageContainer = styled.div`
  padding: 2rem;
`;

const PageHeader = styled.div`
  margin-bottom: 2rem;
`;

const PageTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const PageDescription = styled.p`
  color: var(--text-light);
`;

const NotificationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.$active ? 'var(--primary)' : 'white'};
  color: ${props => props.$active ? 'white' : 'var(--text)'};
  border: 1px solid ${props => props.$active ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$active ? 'var(--primary)' : 'var(--background)'};
  }
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: var(--background);
  color: var(--text);
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: white;
  }
`;

const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const NotificationCard = styled.div`
  padding: 1.5rem;
  background-color: white;
  border: 1px solid var(--border);
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 1rem;
  
  ${props => props.$unread && `
    border-left: 3px solid var(--primary);
    background-color: rgba(16, 185, 129, 0.03);
  `}
`;

const NotificationIconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${props => {
    switch (props.$type) {
      case 'risk':
        return 'rgba(239, 68, 68, 0.1)';
      case 'report':
        return 'rgba(16, 185, 129, 0.1)';
      case 'analytics':
        return 'rgba(59, 130, 246, 0.1)';
      default:
        return 'rgba(107, 114, 128, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.$type) {
      case 'risk':
        return 'rgb(239, 68, 68)';
      case 'report':
        return 'rgb(16, 185, 129)';
      case 'analytics':
        return 'rgb(59, 130, 246)';
      default:
        return 'rgb(107, 114, 128)';
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  flex-shrink: 0;
`;

const NotificationContent = styled.div`
  flex: 1;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

const NotificationTitle = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

const NotificationTime = styled.div`
  color: var(--text-light);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const NotificationMessage = styled.div`
  margin-bottom: 0.5rem;
`;

const NotificationMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const NotificationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  color: var(--text-light);
`;

const NotificationTag = styled.span`
  padding: 0.25rem 0.5rem;
  background-color: ${props => {
    switch (props.$type) {
      case 'risk':
        return 'rgba(239, 68, 68, 0.1)';
      case 'report':
        return 'rgba(16, 185, 129, 0.1)';
      case 'analytics':
        return 'rgba(59, 130, 246, 0.1)';
      default:
        return 'rgba(107, 114, 128, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.$type) {
      case 'risk':
        return 'rgb(239, 68, 68)';
      case 'report':
        return 'rgb(16, 185, 129)';
      case 'analytics':
        return 'rgb(59, 130, 246)';
      default:
        return 'rgb(107, 114, 128)';
    }
  }};
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
`;

const NotificationActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const NotificationAction = styled.button`
  background: none;
  border: none;
  color: var(--text-light);
  cursor: pointer;
  padding: 0.25rem;
  
  &:hover {
    color: var(--text);
  }
`;

const EmptyState = styled.div`
  padding: 3rem;
  text-align: center;
  background-color: white;
  border-radius: 0.75rem;
  border: 1px dashed var(--border);
`;

const EmptyStateIcon = styled.div`
  font-size: 3rem;
  color: var(--text-light);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

const EmptyStateTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const EmptyStateText = styled.p`
  color: var(--text-light);
  max-width: 400px;
  margin: 0 auto;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.25rem;
`;

const PageButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$active ? 'var(--primary)' : 'white'};
  color: ${props => props.$active ? 'white' : 'var(--text)'};
  border: 1px solid ${props => props.$active ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.375rem;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.$active ? 'var(--primary)' : 'var(--background)'};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  
  useEffect(() => {
    // Load notifications from the notification service
    const fetchData = async () => {
      try {
        setLoading(true);
        const notificationsData = await notificationService.fetchNotifications();
        setNotifications(notificationsData);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  const handleMarkAsRead = async (id) => {
    try {
      await notificationService.markAsRead(id);
      setNotifications(notifications.map(n => 
        n.id === id ? { ...n, unread: false } : n
      ));
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };
  
  const handleMarkAllAsRead = async () => {
    try {
      await notificationService.markAllAsRead();
      setNotifications(notifications.map(n => ({ ...n, unread: false })));
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };
  
  const handleDismiss = async (id) => {
    try {
      await notificationService.dismissNotification(id);
      setNotifications(notifications.filter(n => n.id !== id));
    } catch (error) {
      console.error('Failed to dismiss notification:', error);
    }
  };
  
  const filteredNotifications = filter === 'all' 
    ? notifications 
    : filter === 'unread' 
      ? notifications.filter(n => n.unread) 
      : notifications.filter(n => n.type === filter);
  
  const getIcon = (type) => {
    switch (type) {
      case 'risk':
        return <FiAlertTriangle />;
      case 'report':
        return <FiFile />;
      case 'analytics':
        return <FiBarChart2 />;
      default:
        return <FiBell />;
    }
  };
  
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Notifications</PageTitle>
        <PageDescription>
          Stay updated on important alerts, reports, and activity related to your assets.
        </PageDescription>
      </PageHeader>
      
      <NotificationControls>
        <FilterContainer>
          <FilterButton 
            $active={filter === 'all'}
            onClick={() => setFilter('all')}
          >
            All
          </FilterButton>
          <FilterButton 
            $active={filter === 'unread'} 
            onClick={() => setFilter('unread')}
          >
            Unread
          </FilterButton>
          <FilterButton 
            $active={filter === 'risk'} 
            onClick={() => setFilter('risk')}
          >
            Risks
          </FilterButton>
          <FilterButton 
            $active={filter === 'report'} 
            onClick={() => setFilter('report')}
          >
            Reports
          </FilterButton>
          <FilterButton 
            $active={filter === 'analytics'} 
            onClick={() => setFilter('analytics')}
          >
            Analytics
          </FilterButton>
        </FilterContainer>
        
        <ActionButton onClick={handleMarkAllAsRead}>
          <FiCheck size={14} /> Mark all as read
        </ActionButton>
      </NotificationControls>
      
      {loading ? (
        <div>Loading notifications...</div>
      ) : filteredNotifications.length > 0 ? (
        <NotificationList>
          {filteredNotifications.map(notification => (
            <NotificationCard 
              key={notification.id} 
              $unread={notification.unread}
            >
              <NotificationIconContainer $type={notification.type}>
                {getIcon(notification.type)}
              </NotificationIconContainer>
              
              <NotificationContent>
                <NotificationHeader>
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <NotificationTime>
                    <FiClock size={14} />
                    {notification.time}
                  </NotificationTime>
                </NotificationHeader>
                
                <NotificationMessage>
                  {notification.message}
                </NotificationMessage>
                
                <NotificationMeta>
                  <NotificationInfo>
                    <NotificationTag $type={notification.type}>
                      {notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}
                    </NotificationTag>
                    <span>{notification.asset}</span>
                  </NotificationInfo>
                  
                  <NotificationActions>
                    {notification.unread && (
                      <NotificationAction 
                        title="Mark as read"
                        onClick={() => handleMarkAsRead(notification.id)}
                      >
                        <FiCheck />
                      </NotificationAction>
                    )}
                    <NotificationAction 
                      title="Dismiss"
                      onClick={() => handleDismiss(notification.id)}
                    >
                      <FiX />
                    </NotificationAction>
                  </NotificationActions>
                </NotificationMeta>
              </NotificationContent>
            </NotificationCard>
          ))}
        </NotificationList>
      ) : (
        <EmptyState>
          <EmptyStateIcon>
            <FiCheck />
          </EmptyStateIcon>
          <EmptyStateTitle>All caught up!</EmptyStateTitle>
          <EmptyStateText>
            You have no notifications at the moment. When there are updates or alerts about your assets, they will appear here.
          </EmptyStateText>
        </EmptyState>
      )}
      
      {filteredNotifications.length > 0 && (
        <Pagination>
          <PageButton disabled>{'<'}</PageButton>
          <PageButton $active>1</PageButton>
          <PageButton disabled>{'>'}</PageButton>
        </Pagination>
      )}
    </PageContainer>
  );
};

export default Notifications; 