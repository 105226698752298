import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Bar, Line } from 'react-chartjs-2';
import { 
  FiEdit, 
  FiAlertTriangle, 
  FiBarChart2, 
  FiMapPin, 
  FiCalendar, 
  FiDollarSign, 
  FiArrowLeft,
  FiActivity,
  FiDroplet,
  FiSun,
  FiWind,
  FiTrendingUp,
  FiThermometer,
  FiCloud,
  FiShield
} from 'react-icons/fi';
import { getAssetById, getRiskAssessmentsByAssetId } from '../data/mockData';

// Styled components
const AssetDetailContainer = styled.div`
  padding: 1rem 0;
  overflow-x: hidden;
  max-width: 100%;
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  color: var(--text-light);
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    color: var(--primary);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const HeaderInfo = styled.div`
  flex: 1;
`;

const HeaderActions = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const ActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: ${props => props.$primary ? 'var(--primary)' : 'white'};
  color: ${props => props.$primary ? 'white' : 'var(--text)'};
  border: 1px solid ${props => props.$primary ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: default;
  opacity: 0.8;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.5rem;
`;

const AssetType = styled.div`
  color: var(--text-light);
  font-size: 1rem;
  margin-bottom: 0.75rem;
`;

const RiskBadge = styled.span`
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  background-color: ${props => 
    props.$level === 'high' ? 'rgba(239, 68, 68, 0.1)' : 
    props.$level === 'medium' ? 'rgba(245, 158, 11, 0.1)' : 
    'rgba(16, 185, 129, 0.1)'
  };
  color: ${props => 
    props.$level === 'high' ? 'var(--error)' : 
    props.$level === 'medium' ? 'var(--warning)' : 
    'var(--success)'
  };
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const DetailSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 100%;
  overflow-x: hidden;
  
  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--border);
  }
`;

const MetaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
  
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const MetaItem = styled.div`
  margin-bottom: 1rem;
  
  .label {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-bottom: 0.25rem;
  }
  
  .value {
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }
  
  .icon {
    color: var(--text-light);
  }
`;

const RiskFactorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RiskFactor = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border);
  
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .name {
    font-weight: 500;
  }
  
  .score {
    font-weight: 600;
    color: ${props => 
      props.$score > 70 ? 'var(--error)' : 
      props.$score > 40 ? 'var(--warning)' : 
      'var(--success)'
    };
  }
  
  .progress-bar {
    height: 8px;
    width: 100%;
    background-color: #e5e7eb;
    border-radius: 4px;
    overflow: hidden;
    
    .progress {
      height: 100%;
      background-color: ${props => 
        props.$score > 70 ? 'var(--error)' : 
        props.$score > 40 ? 'var(--warning)' : 
        'var(--success)'
      };
      width: ${props => `${props.$score}%`};
      border-radius: 4px;
    }
  }
  
  .weight {
    font-size: 0.8rem;
    color: var(--text-light);
    margin-top: 0.25rem;
    text-align: right;
  }
`;

const SectionHeader = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
`;

const AssessmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const AssessmentCard = styled(Link)`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  text-decoration: none;
  color: var(--text);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .date {
    font-size: 0.85rem;
    color: var(--text-light);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .risk-gauge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }
  
  .score-circle {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(
      ${props => 
        props.$score > 70 ? 'var(--error)' : 
        props.$score > 40 ? 'var(--warning)' : 
        'var(--success)'
      } ${props => props.$score * 3.6}deg, 
      #e5e7eb ${props => props.$score * 3.6}deg 360deg
    );
    display: flex;
    align-items: center;
    justify-content: center;
    
    &::before {
      content: '';
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: white;
    }
    
    .score-value {
      position: relative;
      font-size: 1.2rem;
      font-weight: 700;
      z-index: 1;
    }
  }
`;

const EcosystemDependencySection = styled(DetailSection)`
  width: 100%;
  overflow-x: hidden;
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    color: var(--text);
  }
  
  p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: var(--text);
    margin-bottom: 1rem;
  }
`;

const EcosystemDependencyCard = styled.div`
  background-color: ${props => 
    props.$risk === 'critical' ? 'rgba(239, 68, 68, 0.05)' : 
    props.$risk === 'high' ? 'rgba(245, 158, 11, 0.05)' : 
    props.$risk === 'medium' ? 'rgba(59, 130, 246, 0.05)' : 
    'rgba(16, 185, 129, 0.05)'
  };
  border: 1px solid ${props => 
    props.$risk === 'critical' ? 'rgba(239, 68, 68, 0.2)' : 
    props.$risk === 'high' ? 'rgba(245, 158, 11, 0.2)' : 
    props.$risk === 'medium' ? 'rgba(59, 130, 246, 0.2)' : 
    'rgba(16, 185, 129, 0.2)'
  };
  border-radius: 0.5rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  max-width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .title {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }

  .type {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-top: 0.25rem;
  }

  .risk-badge {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${props => 
      props.$risk === 'critical' ? 'rgba(239, 68, 68, 0.1)' : 
      props.$risk === 'high' ? 'rgba(245, 158, 11, 0.1)' : 
      props.$risk === 'medium' ? 'rgba(59, 130, 246, 0.1)' : 
      'rgba(16, 185, 129, 0.1)'
    };
    color: ${props => 
      props.$risk === 'critical' ? 'var(--error)' : 
      props.$risk === 'high' ? 'var(--warning)' : 
      props.$risk === 'medium' ? 'var(--primary)' : 
      'var(--success)'
    };
  }

  .description {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .impact {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--text);
  }
`;

const ClimateImpactSection = styled(DetailSection)`
  width: 100%;
  overflow-x: hidden;
  
  .impact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
    
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  
  .impact-item {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1rem;
    
    .icon {
      color: var(--primary);
      margin-bottom: 0.5rem;
    }
    
    .name {
      font-weight: 600;
      font-size: 0.9rem;
      margin-bottom: 0.25rem;
    }
    
    .value {
      font-size: 1rem;
      font-weight: 500;
      color: ${props => props.$negative ? 'var(--error)' : 'var(--text)'};
    }
    
    .trend {
      font-size: 0.85rem;
      color: var(--text-light);
      margin-top: 0.25rem;
    }
  }
`;

const OperationsSection = styled(DetailSection)`
  width: 100%;
  overflow-x: hidden;
  
  .operations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .operation-card {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    ul {
      margin: 0;
      padding-left: 1.5rem;
      
      li {
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
      }
    }
    
    .metric {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 0;
      border-bottom: 1px solid var(--border);
      
      &:last-child {
        border-bottom: none;
      }
      
      .metric-name {
        font-size: 0.9rem;
      }
      
      .metric-value {
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
  }
`;

// Add a new styled component for the financial impact analysis
const FinancialImpactSection = styled(DetailSection)`
  margin-top: 2rem;
  width: 100%;
  overflow-x: hidden;
  
  .impact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .impact-chart {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    width: 100%;
    overflow: hidden;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }
  
  .impact-table {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 0.9rem;
      
      th, td {
        padding: 0.75rem;
        text-align: left;
        border-bottom: 1px solid var(--border);
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      
      th {
        font-weight: 600;
      }
      
      tr:last-child td {
        border-bottom: none;
      }
      
      .value-cell {
        text-align: right;
        font-weight: 600;
      }
      
      .negative {
        color: var(--error);
      }
      
      .positive {
        color: var(--success);
      }
    }
  }
  
  .scenario-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    overflow-x: auto;
    padding-bottom: 0.5rem;
    max-width: 100%;
  }
  
  .scenario-tab {
    padding: 0.5rem 1rem;
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;
    
    &.active {
      background-color: var(--primary);
      color: white;
      border-color: var(--primary);
    }
  }
`;

// Complete the SupplyChainSection styled component
const SupplyChainSection = styled(DetailSection)`
  margin-top: 2rem;
  width: 100%;
  overflow-x: hidden;
  
  .supply-chain-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .map-container {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    
    .map-placeholder {
      background-color: #f1f5f9;
      height: 300px;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-light);
      font-size: 0.9rem;
    }
  }
  
  .risk-table {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 0.9rem;
      
      th, td {
        padding: 0.75rem;
        text-align: left;
        border-bottom: 1px solid var(--border);
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      
      th {
        font-weight: 600;
      }
      
      tr:last-child td {
        border-bottom: none;
      }
      
      .risk-indicator {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      
      .risk-high {
        background-color: var(--error);
      }
      
      .risk-medium {
        background-color: var(--warning);
      }
      
      .risk-low {
        background-color: var(--success);
      }
    }
  }
  
  .regulatory-table {
    margin-top: 1.5rem;
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 0.9rem;
      
      th, td {
        padding: 0.75rem;
        text-align: left;
        border-bottom: 1px solid var(--border);
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      
      th {
        font-weight: 600;
      }
      
      tr:last-child td {
        border-bottom: none;
      }
      
      .status {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        font-weight: 600;
      }
      
      .compliant {
        background-color: rgba(16, 185, 129, 0.1);
        color: var(--success);
      }
      
      .at-risk {
        background-color: rgba(245, 158, 11, 0.1);
        color: var(--warning);
      }
      
      .non-compliant {
        background-color: rgba(239, 68, 68, 0.1);
        color: var(--error);
      }
    }
  }
`;

// Add styled component for Resilience Strategies Section
const ResilienceStrategiesSection = styled(DetailSection)`
  margin-top: 2rem;
  width: 100%;
  overflow-x: hidden;
  
  .strategies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .strategy-card {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    ul {
      padding-left: 1.5rem;
      margin: 0;
      
      li {
        margin-bottom: 0.75rem;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
    .implementation-metrics {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid var(--border);
      
      .metric {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        
        .label {
          color: var(--text-light);
        }
        
        .value {
          font-weight: 500;
        }
      }
    }
  }
`;

// Add styled components for the navigation chips
const NavigationChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const NavChip = styled.button`
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 20px;
  padding: 0.35rem 0.75rem;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
  }
`;

const AssetDetail = () => {
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [activeScenario, setActiveScenario] = useState('current');
  const { id } = useParams();
  const navigate = useNavigate();

  // Function to determine the overall risk level based on assessments
  const determineOverallRisk = (assessments) => {
    if (!assessments || assessments.length === 0) {
      return 'Unknown';
    }
    
    // Calculate average score from assessments
    const totalScore = assessments.reduce((sum, assessment) => {
      return sum + assessment.overallScore;
    }, 0);
    
    const averageScore = totalScore / assessments.length;
    
    // Determine risk level based on score
    if (averageScore >= 75) {
      return 'High';
    } else if (averageScore >= 40) {
      return 'Medium';
    } else {
      return 'Low';
    }
  };

  useEffect(() => {
    // Fetch asset and risk assessment data
    const fetchData = async () => {
      setLoading(true);
      try {
        // Log the ID from URL to debug
        console.log('Asset ID from URL:', id, 'Type:', typeof id);
        
        // Try different ways to match asset ID
        const numericId = parseInt(id);
        console.log('Parsed numeric ID:', numericId);
        
        // Direct lookup from the assets array for debugging
        const assetData = await getAssetById(numericId);
        
        console.log('Asset data found:', assetData);
        
        // Add a fallback if the asset isn't found by ID
        if (!assetData) {
          console.error(`Asset with ID ${id} not found, attempting direct import`);
          
          // Import assets directly to see what's available
          const { assets } = await import('../data/mockData');
          console.log('Available assets:', assets.map(a => ({ id: a.id, name: a.name })));
          
          // Try to find by ID directly from assets array
          const directAsset = assets.find(a => a.id === numericId);
          
          if (directAsset) {
            console.log('Found asset directly:', directAsset);
            setAsset(directAsset);
            return;
          }
          
          // Set asset to null if still not found
          setAsset(null);
          return;
        }
        
        const assessmentData = await getRiskAssessmentsByAssetId(numericId);
        
        setAsset(assetData);
        setAssessments(assessmentData);
      } catch (error) {
        console.error('Error fetching asset data:', error);
        // Set asset to null on error
        setAsset(null);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [id]); // Remove navigate from dependency array

  if (loading) {
    return <div>Loading asset data...</div>;
  }

  if (!asset) {
    return <div>Asset not found</div>;
  }

  // Calculate overall risk level
  const overallRisk = determineOverallRisk(assessments);

  // Function to render risk history chart with responsive settings
  const renderRiskHistoryChart = () => {
    return (
      <DetailSection>
        <div style={{ width: '100%', height: 'auto', maxHeight: '400px' }}>
          <Line 
            data={{
              labels: ['2019 Q1', '2019 Q2', '2019 Q3', '2019 Q4', '2020 Q1', '2020 Q2', '2020 Q3', '2020 Q4', '2021 Q1', '2021 Q2', '2021 Q3', '2021 Q4', '2022 Q1', '2022 Q2', '2022 Q3', '2022 Q4', '2023 Q1', '2023 Q2', '2023 Q3', '2023 Q4'],
              datasets: [
                {
                  label: 'Risk Score',
                  data: [45, 47, 50, 52, 55, 58, 60, 63, 65, 67, 69, 70, 72, 73, 75, 76, 77, 78, 78, 78],
                  borderColor: asset.riskScore > 70 ? 'var(--error)' : asset.riskScore > 40 ? 'var(--warning)' : 'var(--success)',
                  backgroundColor: `${asset.riskScore > 70 ? 'rgba(239, 68, 68, 0.1)' : asset.riskScore > 40 ? 'rgba(245, 158, 11, 0.1)' : 'rgba(16, 185, 129, 0.1)'}`,
                  tension: 0.3,
                  fill: true
                }
              ]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: false
                }
              },
              scales: {
                y: {
                  min: 0,
                  max: 100,
                  title: {
                    display: true,
                    text: 'Risk Score'
                  }
                }
              }
            }}
          />
        </div>
      </DetailSection>
    );
  };

  return (
    <AssetDetailContainer>
      <BackButton to="/dashboard">
        <FiArrowLeft /> Back to Dashboard
      </BackButton>
      
      <Header>
        <HeaderInfo>
          <Title>
            {asset.name}
            <RiskBadge $level={overallRisk.toLowerCase()}>
              {overallRisk} Risk
            </RiskBadge>
          </Title>
          <AssetType>{asset.type} • {asset.location}</AssetType>
        </HeaderInfo>
        
        <HeaderActions>
          <ActionButton>
            <FiBarChart2 /> View Risk Assessment
          </ActionButton>
          <ActionButton $primary>
            <FiEdit /> Edit Asset
          </ActionButton>
        </HeaderActions>
      </Header>
      
      <NavigationChips>
        <NavChip onClick={() => document.getElementById('assetDetails').scrollIntoView({behavior: 'smooth'})}>
          Asset Details
        </NavChip>
        <NavChip onClick={() => document.getElementById('riskProfile').scrollIntoView({behavior: 'smooth'})}>
          Risk Profile
        </NavChip>
        <NavChip onClick={() => document.getElementById('operations').scrollIntoView({behavior: 'smooth'})}>
          Operations
        </NavChip>
        <NavChip onClick={() => document.getElementById('ecosystemDependencies').scrollIntoView({behavior: 'smooth'})}>
          Ecosystem Dependencies
        </NavChip>
        <NavChip onClick={() => document.getElementById('resilienceStrategies').scrollIntoView({behavior: 'smooth'})}>
          Resilience Strategies
        </NavChip>
        <NavChip onClick={() => document.getElementById('climateImpact').scrollIntoView({behavior: 'smooth'})}>
          Climate Impact
        </NavChip>
        <NavChip onClick={() => document.getElementById('financialImpact').scrollIntoView({behavior: 'smooth'})}>
          Financial Impact
        </NavChip>
        <NavChip onClick={() => document.getElementById('supplyChain').scrollIntoView({behavior: 'smooth'})}>
          Supply Chain
        </NavChip>
        <NavChip onClick={() => document.getElementById('riskHistory').scrollIntoView({behavior: 'smooth'})}>
          Risk History
        </NavChip>
      </NavigationChips>
      
      <DetailGrid>
        <div>
          <DetailSection id="assetDetails">
            <h2>Asset Details</h2>
            <MetaGrid>
              <MetaItem>
                <div className="label">Asset Type</div>
                <div className="value">
                  <span className="icon"><FiBarChart2 /></span> {asset.type}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Location</div>
                <div className="value">
                  <span className="icon"><FiMapPin /></span> {asset.location}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Geographic Coordinates</div>
                <div className="value">
                  <span className="icon"><FiMapPin /></span> 
                  {asset.coordinates ? 
                    `${asset.coordinates.lat.toFixed(4)}°N, ${asset.coordinates.lng.toFixed(4)}°W` : 
                    (asset.latitude && asset.longitude) ? 
                      `${asset.latitude}°N, ${asset.longitude}°W` : 
                      '47.6062°N, 122.3321°W'
                  }
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Region/Municipality</div>
                <div className="value">
                  <span className="icon"><FiMapPin /></span> {asset.region || asset.municipality || (asset.location.includes(',') ? asset.location.split(',')[1].trim() : 'King County')}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Acquisition Date</div>
                <div className="value">
                  <span className="icon"><FiCalendar /></span> {asset.acquisitionDate || 'March 15, 2018'}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Last Assessment</div>
                <div className="value">
                  <span className="icon"><FiCalendar /></span> {asset.lastAssessment || (assessments.length > 0 ? new Date(assessments[assessments.length-1].date).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'}) : 'June 10, 2023')}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Total Value</div>
                <div className="value">
                  <span className="icon"><FiDollarSign /></span> {asset.value}
                </div>
              </MetaItem>
              <MetaItem>
                <div className="label">Size/Area</div>
                <div className="value">
                  {asset.size || '35,000 sq ft'}
                </div>
              </MetaItem>
            </MetaGrid>
          </DetailSection>
          
          <DetailSection id="riskProfile">
            <h2>Risk Profile</h2>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              marginBottom: '1.5rem'
            }}>
              <div style={{ 
                position: 'relative', 
                width: '120px', 
                height: '120px', 
                borderRadius: '50%',
                background: `conic-gradient(
                  ${
                    asset.riskScore > 70 ? 'var(--error)' : 
                    asset.riskScore > 40 ? 'var(--warning)' : 
                    'var(--success)'
                  } ${asset.riskScore * 3.6}deg, 
                  #e5e7eb ${asset.riskScore * 3.6}deg 360deg
                )`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <div style={{ 
                  position: 'absolute', 
                  width: '90px', 
                  height: '90px', 
                  borderRadius: '50%', 
                  backgroundColor: 'white'
                }}></div>
                <div style={{ 
                  position: 'relative', 
                  fontSize: '2rem',
                  fontWeight: '700',
                  zIndex: 1
                }}>
                  {asset.riskScore}
                </div>
              </div>
            </div>
            
            <RiskFactorsList>
              {asset.riskFactors && asset.riskFactors.map(factor => (
                <RiskFactor key={factor.name} $score={factor.score}>
                  <div className="header">
                    <div className="name">{factor.name}</div>
                    <div className="score">{factor.score}</div>
                  </div>
                  <div className="progress-bar">
                    <div className="progress"></div>
                  </div>
                  <div className="weight">Weight: {factor.weight * 100}%</div>
                </RiskFactor>
              ))}
            </RiskFactorsList>
          </DetailSection>
        </div>
        
        <OperationsSection id="operations">
          <h2>Asset Operations & Performance</h2>
          
          <div className="operations-grid">
            <div className="operation-card">
              <h3><FiActivity size={18} style={{ color: 'var(--primary)' }} /> Key Performance Indicators</h3>
              <div className="metric">
                <div className="metric-name">Annual Revenue</div>
                <div className="metric-value">${(asset.value * 0.12).toLocaleString()}</div>
              </div>
              <div className="metric">
                <div className="metric-name">Operating Expenses</div>
                <div className="metric-value">${(asset.value * 0.08).toLocaleString()}</div>
              </div>
              <div className="metric">
                <div className="metric-name">Resource Efficiency</div>
                <div className="metric-value">{asset.risk === 'high' ? 'Low' : asset.risk === 'medium' ? 'Medium' : 'High'}</div>
              </div>
              <div className="metric">
                <div className="metric-name">Occupancy Rate</div>
                <div className="metric-value">{85 - (asset.riskScore * 0.2).toFixed(1)}%</div>
              </div>
            </div>
            
            <div className="operation-card">
              <h3><FiShield size={18} style={{ color: 'var(--primary)' }} /> Climate Resilience Measures</h3>
              <ul>
                {asset.name === 'Mountain Resort E' && (
                  <>
                    <li>Water conservation infrastructure implemented (2021)</li>
                    <li>Snowmaking equipment upgraded to reduce water consumption</li>
                    <li>Wildfire defensive perimeter established</li>
                    <li>Summer activity diversification in progress</li>
                  </>
                )}
                {asset.name === 'Oceanside Hotel Complex' && (
                  <>
                    <li>Coastal erosion barriers installed (2022)</li>
                    <li>Hurricane-resistant building reinforcement</li>
                    <li>Rainwater harvesting system implemented</li>
                    <li>Energy-efficient HVAC system upgrades planned</li>
                  </>
                )}
                {asset.name === 'Alpine Winery' && (
                  <>
                    <li>Drought-resistant grape varietals introduced</li>
                    <li>Precision irrigation system installed</li>
                    <li>Shade cloth deployment for heat wave protection</li>
                    <li>Water recycling system implemented</li>
                  </>
                )}
                {asset.name === 'Urban Office Complex' && (
                  <>
                    <li>Green roof installation to reduce urban heat island effect</li>
                    <li>Stormwater management system upgraded</li>
                    <li>Energy-efficient window replacements (2022)</li>
                    <li>EV charging infrastructure expanded</li>
                  </>
                )}
              </ul>
            </div>
            
            <div className="operation-card">
              <h3><FiTrendingUp size={18} style={{ color: 'var(--primary)' }} /> Resource Consumption</h3>
              <div className="metric">
                <div className="metric-name">Annual Water Usage</div>
                <div className="metric-value">{asset.type === 'Hospitality' ? '32,500' : asset.type === 'Agriculture' ? '48,200' : '12,800'} m³</div>
              </div>
              <div className="metric">
                <div className="metric-name">Energy Consumption</div>
                <div className="metric-value">{asset.type === 'Hospitality' || asset.type === 'Commercial Real Estate' ? '4.8' : '2.1'} GWh/year</div>
              </div>
              <div className="metric">
                <div className="metric-name">Waste Generated</div>
                <div className="metric-value">{asset.type === 'Hospitality' ? '285' : asset.type === 'Commercial Real Estate' ? '190' : '120'} tons/year</div>
              </div>
              <div className="metric">
                <div className="metric-name">Carbon Footprint</div>
                <div className="metric-value">{asset.type === 'Hospitality' ? '1,850' : asset.type === 'Commercial Real Estate' ? '1,450' : '980'} tCO₂e/year</div>
              </div>
            </div>
            
            <div className="operation-card">
              <h3><FiBarChart2 size={18} style={{ color: 'var(--primary)' }} /> Climate Vulnerability</h3>
              <div className="metric">
                <div className="metric-name">Temperature Sensitivity</div>
                <div className="metric-value" style={{ color: asset.name === 'Mountain Resort E' || asset.name === 'Alpine Winery' ? 'var(--error)' : 'var(--warning)' }}>
                  {asset.name === 'Mountain Resort E' || asset.name === 'Alpine Winery' ? 'High' : 'Medium'}
                </div>
              </div>
              <div className="metric">
                <div className="metric-name">Precipitation Sensitivity</div>
                <div className="metric-value" style={{ color: asset.name === 'Alpine Winery' ? 'var(--error)' : 'var(--warning)' }}>
                  {asset.name === 'Alpine Winery' ? 'High' : 'Medium'}
                </div>
              </div>
              <div className="metric">
                <div className="metric-name">Extreme Weather Exposure</div>
                <div className="metric-value" style={{ color: asset.name === 'Oceanside Hotel Complex' ? 'var(--error)' : 'var(--warning)' }}>
                  {asset.name === 'Oceanside Hotel Complex' ? 'High' : 'Medium'}
                </div>
              </div>
              <div className="metric">
                <div className="metric-name">Sea Level Rise Exposure</div>
                <div className="metric-value" style={{ color: asset.name === 'Oceanside Hotel Complex' ? 'var(--error)' : asset.name === 'Urban Office Complex' ? 'var(--warning)' : 'var(--success)' }}>
                  {asset.name === 'Oceanside Hotel Complex' ? 'High' : asset.name === 'Urban Office Complex' ? 'Medium' : 'Low'}
                </div>
              </div>
            </div>
          </div>
        </OperationsSection>
        
        <EcosystemDependencySection id="ecosystemDependencies">
          <h2>Ecosystem Dependencies</h2>
          <p>These natural systems provide ecosystem services that this asset depends on for operations and value.</p>
          
          {asset.ecosystemDependencies && asset.ecosystemDependencies.map((dependency, index) => (
            <EcosystemDependencyCard key={index} $risk={dependency.risk}>
              <div className="header">
                <div>
                  <div className="title">{dependency.name}</div>
                  <div className="type">{dependency.type}</div>
                </div>
                <div className="risk-badge">
                  {dependency.risk.charAt(0).toUpperCase() + dependency.risk.slice(1)} Risk
                </div>
              </div>
              
              <div className="description">
                {dependency.description}
              </div>
              
              <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                <strong>Climate Impact Pathways:</strong>
              </div>
              <ul style={{ margin: "0 0 1rem 0", paddingLeft: "1.5rem", fontSize: "0.9rem" }}>
                {dependency.name === "Alpine Snowpack" && (
                  <>
                    <li>Rising temperatures reduce seasonal snow accumulation and accelerate spring melt</li>
                    <li>Increasing precipitation variability affects snow quality and distribution</li>
                    <li>Shifting precipitation from snow to rain reduces seasonal snowpack</li>
                  </>
                )}
                {dependency.name === "Mountain Watershed" && (
                  <>
                    <li>Earlier snowmelt alters timing of water availability</li>
                    <li>Increasing drought frequency reduces summer streamflow</li>
                    <li>Higher evapotranspiration rates reduce water yield from forested areas</li>
                  </>
                )}
                {dependency.name === "Regional Food Systems" && (
                  <>
                    <li>Changing growing seasons affect production patterns and food quality</li>
                    <li>Increasing pest and disease pressure disrupts agricultural yields</li>
                    <li>Extreme weather events damage crops and disrupt supply chains</li>
                  </>
                )}
                {dependency.name === "Coral Reef Ecosystem" && (
                  <>
                    <li>Ocean warming causes thermal stress and coral bleaching events</li>
                    <li>Ocean acidification reduces calcification rates and reef structure</li>
                    <li>Increasing storm intensity causes physical damage to reef systems</li>
                  </>
                )}
                {dependency.name === "Coastal Beach Systems" && (
                  <>
                    <li>Sea level rise accelerates coastal erosion and reduces beach area</li>
                    <li>Increasing storm surge intensity damages beach infrastructure</li>
                    <li>Changes in sediment transport affect beach formation and maintenance</li>
                  </>
                )}
                {dependency.name === "Marine Fisheries" && (
                  <>
                    <li>Ocean warming shifts species ranges and migration patterns</li>
                    <li>Changing ocean currents affect nutrient availability and fish populations</li>
                    <li>Acidification impacts shellfish and reef-dependent fisheries</li>
                  </>
                )}
                {dependency.name === "Mediterranean Climate" && (
                  <>
                    <li>Increasing extreme heat days during growing season reduce fruit quality</li>
                    <li>Changing precipitation patterns affect vineyard irrigation needs</li>
                    <li>Shifting seasons disrupt historical grape development timing</li>
                  </>
                )}
                {dependency.name === "Local Watershed" && (
                  <>
                    <li>Decreasing snowpack reduces water storage and summer water availability</li>
                    <li>Higher temperatures increase evaporative water demand</li>
                    <li>Increasing drought frequency limits water allocations for agriculture</li>
                  </>
                )}
                {dependency.name === "Pollinator Systems" && (
                  <>
                    <li>Warming temperatures cause phenological mismatches with flowering periods</li>
                    <li>Habitat loss and fragmentation reduce pollinator diversity and abundance</li>
                    <li>Increasing pesticide use and disease pressure affect pollinator health</li>
                  </>
                )}
                {dependency.name === "Urban Tree Canopy" && (
                  <>
                    <li>Heat stress and drought affect urban tree health and mortality rates</li>
                    <li>Changing pest and disease pressures impact tree species viability</li>
                    <li>Extreme weather events increase tree damage and loss</li>
                  </>
                )}
                {dependency.name === "Regional Watershed" && (
                  <>
                    <li>Changing precipitation patterns affect reservoir levels and hydropower generation</li>
                    <li>Increasing drought frequency impacts water availability for industrial use</li>
                    <li>Higher temperatures increase water demand while decreasing supply</li>
                  </>
                )}
                {dependency.name === "Agricultural Systems" && (
                  <>
                    <li>Changing growing conditions disrupt agricultural supply chains</li>
                    <li>Extreme weather events damage crops and infrastructure</li>
                    <li>Water scarcity impacts irrigation-dependent crops and livestock</li>
                  </>
                )}
                {dependency.name === "Coastal Dune System" && (
                  <>
                    <li>Sea level rise increases erosion of protective dune structures</li>
                    <li>Storm intensification causes dune breaching and overwash</li>
                    <li>Loss of native vegetation reduces dune stability and formation</li>
                  </>
                )}
                {dependency.name === "Nearshore Marine Ecosystem" && (
                  <>
                    <li>Ocean warming and acidification affect habitat quality and biodiversity</li>
                    <li>Sea level rise alters coastal habitats and species distribution</li>
                    <li>Increasing algal blooms and pollution reduce water quality and aesthetics</li>
                  </>
                )}
                {dependency.name === "Coastal Vegetation" && (
                  <>
                    <li>Saltwater intrusion affects freshwater-dependent vegetation</li>
                    <li>Increasing storm intensity damages protective vegetation</li>
                    <li>Rising temperatures and changing precipitation patterns stress native plants</li>
                  </>
                )}
              </ul>
              
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>
                <div>
                  <strong>Adaptation Options:</strong>
                </div>
                <div>
                  <strong>Dependency Strength:</strong> {
                    dependency.risk === 'critical' ? 'Very High' :
                    dependency.risk === 'high' ? 'High' :
                    dependency.risk === 'medium' ? 'Medium' : 'Low'
                  }
                </div>
              </div>
              <ul style={{ margin: "0 0 1rem 0", paddingLeft: "1.5rem", fontSize: "0.9rem" }}>
                {dependency.name === "Alpine Snowpack" && (
                  <>
                    <li>Invest in more efficient snowmaking technology with lower water requirements</li>
                    <li>Diversify into non-snow dependent activities for shoulder seasons</li>
                    <li>Develop high-elevation terrain that retains snow longer</li>
                  </>
                )}
                {dependency.name === "Mountain Watershed" && (
                  <>
                    <li>Implement water recycling and efficiency measures for resort operations</li>
                    <li>Invest in water storage capacity to capture spring melt</li>
                    <li>Participate in watershed restoration and protection efforts</li>
                  </>
                )}
                {dependency.name === "Regional Food Systems" && (
                  <>
                    <li>Develop relationships with diverse suppliers across different growing regions</li>
                    <li>Create menu flexibility to accommodate seasonal and regional availability</li>
                    <li>Support regenerative agriculture practices among local food suppliers</li>
                  </>
                )}
                {dependency.name === "Coral Reef Ecosystem" && (
                  <>
                    <li>Support reef restoration initiatives in adjacent marine areas</li>
                    <li>Implement guest education programs about reef conservation</li>
                    <li>Develop alternative recreation options less dependent on reef health</li>
                  </>
                )}
                {dependency.name === "Coastal Beach Systems" && (
                  <>
                    <li>Implement soft engineering approaches for erosion control</li>
                    <li>Rebuild infrastructure further from the shoreline</li>
                    <li>Invest in beach nourishment and dune restoration projects</li>
                  </>
                )}
                {dependency.name === "Marine Fisheries" && (
                  <>
                    <li>Diversify seafood sourcing to include more climate-resilient species</li>
                    <li>Develop relationships with sustainable aquaculture producers</li>
                    <li>Create flexible menu options to adapt to changing availability</li>
                  </>
                )}
                {dependency.name === "Mediterranean Climate" && (
                  <>
                    <li>Transition to more heat and drought-tolerant grape varieties</li>
                    <li>Implement shade cloth and misting systems for extreme heat protection</li>
                    <li>Explore higher elevation or cooler vineyard sites for future expansion</li>
                  </>
                )}
                {dependency.name === "Local Watershed" && (
                  <>
                    <li>Implement precision irrigation technology to reduce water use</li>
                    <li>Invest in on-site water storage capacity</li>
                    <li>Restore and protect upstream watershed lands</li>
                  </>
                )}
                {dependency.name === "Pollinator Systems" && (
                  <>
                    <li>Create pollinator habitat corridors throughout the property</li>
                    <li>Reduce or eliminate pesticide use that harms pollinators</li>
                    <li>Support wild pollinator conservation in surrounding areas</li>
                  </>
                )}
                {dependency.name === "Urban Tree Canopy" && (
                  <>
                    <li>Increase tree planting with climate-adapted species</li>
                    <li>Implement sustainable water management for urban trees</li>
                    <li>Create green roof and wall systems to supplement tree cooling</li>
                  </>
                )}
                {dependency.name === "Regional Watershed" && (
                  <>
                    <li>Implement water efficiency measures throughout building systems</li>
                    <li>Install on-site water capture and reuse technologies</li>
                    <li>Diversify energy sources beyond hydroelectric power</li>
                  </>
                )}
                {dependency.name === "Agricultural Systems" && (
                  <>
                    <li>Diversify suppliers across multiple agricultural regions</li>
                    <li>Create flexible procurement policies to adapt to changing availability</li>
                    <li>Support regenerative agriculture practices among suppliers</li>
                  </>
                )}
                {dependency.name === "Coastal Dune System" && (
                  <>
                    <li>Protect and restore native dune vegetation</li>
                    <li>Install elevated walkways to reduce dune degradation</li>
                    <li>Implement living shoreline approaches to enhance natural protection</li>
                  </>
                )}
                {dependency.name === "Nearshore Marine Ecosystem" && (
                  <>
                    <li>Support marine habitat restoration initiatives</li>
                    <li>Implement stormwater management to reduce runoff pollution</li>
                    <li>Participate in coastal water quality monitoring programs</li>
                  </>
                )}
                {dependency.name === "Coastal Vegetation" && (
                  <>
                    <li>Restore and protect native salt-tolerant vegetation</li>
                    <li>Create living shoreline features to reduce erosion</li>
                    <li>Implement setbacks for new landscaping to accommodate future conditions</li>
                  </>
                )}
              </ul>
              
              <div className="impact">
                <FiAlertTriangle size={16} style={{ color: dependency.risk === 'critical' ? 'var(--error)' : dependency.risk === 'high' ? 'var(--warning)' : 'var(--primary)' }} />
                Financial Impact: {dependency.impactValue} | Replacement Cost: ${(
                  parseFloat(dependency.impactValue.replace(/[^0-9.]/g, '')) * 
                  (dependency.impactValue.includes('M') ? 1 : 0.001) * 
                  5
                ).toFixed(1)}M
              </div>
            </EcosystemDependencyCard>
          ))}
          
          <ActionButton to={`/simulations`} $primary>
            <FiActivity size={16} /> Run Ecosystem Service Simulation
          </ActionButton>
        </EcosystemDependencySection>
        
        <ResilienceStrategiesSection id="resilienceStrategies">
          <h2>Ecosystem Service Resilience Strategies</h2>
          <p>
            These strategies focus on enhancing the resilience of ecosystem services this asset depends on, 
            reducing vulnerability to climate change impacts while providing financial and operational benefits.
          </p>
          
          <div className="strategies-grid">
            {asset.name === 'Mountain Resort E' && (
              <>
                <div className="strategy-card">
                  <h3><FiActivity style={{ color: 'var(--primary)' }} /> Alpine Snowpack Management</h3>
                  <ul>
                    <li><strong>Snow Farming:</strong> Implement advanced snow harvesting and redistribution techniques to maximize snowpack duration on critical terrain.</li>
                    <li><strong>Slope Aspect Management:</strong> Prioritize development and snowmaking on north-facing slopes that retain snow longer under warming conditions.</li>
                    <li><strong>Vegetation Management:</strong> Selective forest thinning to reduce wind scouring and improve snow retention on ski runs.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$1.2-1.8M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$450K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">3.5 years</div>
                    </div>
                  </div>
                </div>
                <div className="strategy-card">
                  <h3><FiDroplet style={{ color: 'var(--primary)' }} /> Watershed Protection Plan</h3>
                  <ul>
                    <li><strong>Upper Watershed Restoration:</strong> Restore degraded forest lands in the upper watershed to improve water retention and flow regulation.</li>
                    <li><strong>Snowmelt Management:</strong> Develop small-scale water retention systems to capture and gradually release spring snowmelt.</li>
                    <li><strong>Water Recycling System:</strong> Implement closed-loop water recycling for snowmaking to reduce withdrawals from natural water sources.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$2.5M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$320K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">7.8 years</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            
            {asset.name === 'Oceanside Hotel Complex' && (
              <>
                <div className="strategy-card">
                  <h3><FiActivity style={{ color: 'var(--primary)' }} /> Coral Reef Restoration</h3>
                  <ul>
                    <li><strong>Coral Nursery Program:</strong> Establish an on-site coral nursery to grow climate-resilient coral species for reef restoration.</li>
                    <li><strong>Heat-Tolerant Species:</strong> Focus restoration efforts on coral genotypes shown to be resilient to warming ocean temperatures.</li>
                    <li><strong>Reef Structure Enhancement:</strong> Deploy engineered reef structures to provide stable substrate for coral growth in high-energy environments.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$1.8M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Value Protected:</div>
                      <div className="value">$1.2M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">1.5 years</div>
                    </div>
                  </div>
                </div>
                <div className="strategy-card">
                  <h3><FiDroplet style={{ color: 'var(--primary)' }} /> Beach Protection System</h3>
                  <ul>
                    <li><strong>Living Shoreline:</strong> Establish natural infrastructure combining native vegetation, sand dunes, and offshore breakwaters.</li>
                    <li><strong>Dune Restoration:</strong> Rebuild and revegetate coastal dunes to provide natural storm protection and sand replenishment.</li>
                    <li><strong>Offshore Reef Structures:</strong> Deploy submerged breakwaters to reduce wave energy reaching the shoreline during storms.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$3.2M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Value Protected:</div>
                      <div className="value">$2.1M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">1.5 years</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            
            {asset.name === 'Alpine Winery' && (
              <>
                <div className="strategy-card">
                  <h3><FiActivity style={{ color: 'var(--primary)' }} /> Climate-Resilient Viticulture</h3>
                  <ul>
                    <li><strong>Varietal Diversification:</strong> Gradually transition to heat and drought-tolerant grape varieties while maintaining wine quality.</li>
                    <li><strong>Microclimate Engineering:</strong> Implement shade structures, misting systems, and windbreaks to moderate temperature extremes.</li>
                    <li><strong>Soil Carbon Management:</strong> Build soil organic matter to improve water retention and reduce irrigation needs.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$1.5M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$580K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">2.6 years</div>
                    </div>
                  </div>
                </div>
                <div className="strategy-card">
                  <h3><FiDroplet style={{ color: 'var(--primary)' }} /> Water Security Program</h3>
                  <ul>
                    <li><strong>Precision Irrigation:</strong> Implement soil moisture sensors and drip irrigation technologies to optimize water use efficiency.</li>
                    <li><strong>Water Storage Expansion:</strong> Develop on-site water storage capacity to capture winter precipitation for summer use.</li>
                    <li><strong>Wastewater Recycling:</strong> Implement processing wastewater recycling systems for irrigation use.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$1.2M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$350K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">3.4 years</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            
            {asset.name === 'Urban Office Complex' && (
              <>
                <div className="strategy-card">
                  <h3><FiActivity style={{ color: 'var(--primary)' }} /> Urban Cooling Enhancement</h3>
                  <ul>
                    <li><strong>Green Roof Expansion:</strong> Convert remaining conventional roof surfaces to green roof systems with drought-tolerant species.</li>
                    <li><strong>Vertical Gardens:</strong> Install living walls on south and west-facing building surfaces to reduce heat absorption.</li>
                    <li><strong>Tree Canopy Enhancement:</strong> Plant climate-adapted tree species in surrounding property to provide shade and evaporative cooling.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$1.8M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$420K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">4.3 years</div>
                    </div>
                  </div>
                </div>
                <div className="strategy-card">
                  <h3><FiDroplet style={{ color: 'var(--primary)' }} /> Water-Energy Nexus Optimization</h3>
                  <ul>
                    <li><strong>Stormwater Capture:</strong> Implement comprehensive stormwater capture systems for non-potable water use.</li>
                    <li><strong>On-site Renewable Energy:</strong> Expand solar capacity to reduce dependence on grid electricity during peak demand periods.</li>
                    <li><strong>Smart Building Systems:</strong> Implement AI-driven building management systems to optimize water and energy use based on weather forecasts.</li>
                  </ul>
                  <div className="implementation-metrics">
                    <div className="metric">
                      <div className="label">Implementation Cost:</div>
                      <div className="value">$2.2M</div>
                    </div>
                    <div className="metric">
                      <div className="label">Annual Savings:</div>
                      <div className="value">$650K</div>
                    </div>
                    <div className="metric">
                      <div className="label">Break Even:</div>
                      <div className="value">3.4 years</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </ResilienceStrategiesSection>
        
        <ClimateImpactSection id="climateImpact">
          <h2>Climate Impact Projections</h2>
          
          <div className="impact-grid">
            <div className="impact-item">
              <FiThermometer size={20} className="icon" />
              <div className="name">Temperature Increase</div>
              <div className="value">+2.5°C by 2050</div>
              <div className="trend">+0.4°C in last decade</div>
            </div>
            
            <div className="impact-item">
              <FiCloud size={20} className="icon" />
              <div className="name">Precipitation Change</div>
              <div className="value">-15% annual average</div>
              <div className="trend">High seasonal variability</div>
            </div>
            
            <div className="impact-item">
              <FiWind size={20} className="icon" />
              <div className="name">Extreme Weather Events</div>
              <div className="value">+35% frequency</div>
              <div className="trend">Increasing severity</div>
            </div>
            
            <div className="impact-item">
              <FiDroplet size={20} className="icon" />
              <div className="name">Water Availability</div>
              <div className="value">-30% by 2040</div>
              <div className="trend">Drought risk increasing</div>
            </div>
          </div>
          
          <ActionButton to={`/reports`} style={{ marginTop: '1rem' }}>
            <FiBarChart2 size={16} /> View Full Climate Report
          </ActionButton>
        </ClimateImpactSection>
        
        <FinancialImpactSection id="financialImpact">
          <h2>Financial Impact Analysis</h2>
          
          <div className="scenario-container">
            <div 
              className={`scenario-tab ${activeScenario === 'current' ? 'active' : ''}`}
              onClick={() => setActiveScenario('current')}
            >
              Current Conditions
            </div>
            <div 
              className={`scenario-tab ${activeScenario === 'moderate' ? 'active' : ''}`}
              onClick={() => setActiveScenario('moderate')}
            >
              Moderate Warming (2.0°C)
            </div>
            <div 
              className={`scenario-tab ${activeScenario === 'high' ? 'active' : ''}`}
              onClick={() => setActiveScenario('high')}
            >
              High Warming (3.5°C)
            </div>
            <div 
              className={`scenario-tab ${activeScenario === 'adaptation' ? 'active' : ''}`}
              onClick={() => setActiveScenario('adaptation')}
            >
              With Adaptation Measures
            </div>
          </div>
          
          <div className="impact-grid">
            <div className="impact-chart">
              <h3>Value at Risk by Scenario</h3>
              <Bar 
                data={{
                  labels: ['2025', '2030', '2040', '2050'],
                  datasets: [
                    {
                      label: 'Financial Impact',
                      data: activeScenario === 'current' ? 
                            [asset.value * 0.05, asset.value * 0.12, asset.value * 0.18, asset.value * 0.25] :
                            activeScenario === 'moderate' ? 
                            [asset.value * 0.08, asset.value * 0.20, asset.value * 0.32, asset.value * 0.45] :
                            activeScenario === 'high' ? 
                            [asset.value * 0.12, asset.value * 0.28, asset.value * 0.48, asset.value * 0.65] :
                            [asset.value * 0.04, asset.value * 0.09, asset.value * 0.14, asset.value * 0.18],
                      backgroundColor: activeScenario === 'adaptation' ? 'rgba(16, 185, 129, 0.7)' : 'rgba(239, 68, 68, 0.7)',
                    }
                  ]
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          return `Value at Risk: $${Math.round(context.raw / 10000) / 100}M`;
                        }
                      }
                    }
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Value at Risk ($)'
                      },
                      ticks: {
                        callback: function(value) {
                          return '$' + (value / 1000000).toFixed(1) + 'M';
                        }
                      }
                    }
                  }
                }}
              />
            </div>
            
            <div className="impact-table">
              <h3>Financial Impact Summary ({activeScenario === 'current' ? 'Current Conditions' : 
                  activeScenario === 'moderate' ? 'Moderate Warming' : 
                  activeScenario === 'high' ? 'High Warming' : 'With Adaptation'})</h3>
              <table>
                <thead>
                  <tr>
                    <th>Impact Category</th>
                    <th className="value-cell">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Asset Value at Risk</td>
                    <td className="value-cell negative">
                      ${Math.round((activeScenario === 'current' ? 
                             asset.value * 0.25 : 
                             activeScenario === 'moderate' ? 
                             asset.value * 0.45 : 
                             activeScenario === 'high' ? 
                             asset.value * 0.65 : 
                             asset.value * 0.18) / 10000) / 100}M
                    </td>
                  </tr>
                  <tr>
                    <td>Annual Revenue Impact</td>
                    <td className="value-cell negative">
                      -${Math.round((activeScenario === 'current' ? 
                             asset.value * 0.03 : 
                             activeScenario === 'moderate' ? 
                             asset.value * 0.055 : 
                             activeScenario === 'high' ? 
                             asset.value * 0.085 : 
                             asset.value * 0.022) / 10000) / 100}M/year
                    </td>
                  </tr>
                  <tr>
                    <td>Insurance Premium Increase</td>
                    <td className="value-cell negative">
                      +${Math.round((activeScenario === 'current' ? 
                             asset.value * 0.006 : 
                             activeScenario === 'moderate' ? 
                             asset.value * 0.012 : 
                             activeScenario === 'high' ? 
                             asset.value * 0.022 : 
                             asset.value * 0.009) / 10000) / 100}M/year
                    </td>
                  </tr>
                  <tr>
                    <td>Adaptation Cost Estimate</td>
                    <td className="value-cell">
                      ${Math.round((activeScenario === 'current' ? 
                             asset.value * 0.08 : 
                             activeScenario === 'moderate' ? 
                             asset.value * 0.15 : 
                             activeScenario === 'high' ? 
                             asset.value * 0.25 : 
                             asset.value * 0.18) / 10000) / 100}M
                    </td>
                  </tr>
                  <tr>
                    <td>Return on Adaptation Investment</td>
                    <td className="value-cell positive">
                      {(activeScenario === 'current' ? 
                        220 : 
                        activeScenario === 'moderate' ? 
                        280 : 
                        activeScenario === 'high' ? 
                        350 : 
                        280)}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </FinancialImpactSection>
        
        <SupplyChainSection id="supplyChain">
          <h2>Supply Chain & Regulatory Risk</h2>
          
          <div className="supply-chain-grid">
            <div className="map-container">
              <h3>Geographic Supply Chain Risk</h3>
              <div className="map-placeholder">
                Interactive supply chain map visualization would appear here
              </div>
            </div>
            
            <div className="risk-table">
              <h3>Key Supply Chain Dependencies</h3>
              <table>
                <thead>
                  <tr>
                    <th>Input</th>
                    <th>Source Region</th>
                    <th>Climate Risk</th>
                  </tr>
                </thead>
                <tbody>
                  {asset.type === 'Hospitality' && (
                    <>
                      <tr>
                        <td>Fresh Water</td>
                        <td>Local Watershed</td>
                        <td>
                          <span className="risk-indicator risk-high"></span>
                          High
                        </td>
                      </tr>
                      <tr>
                        <td>Food Supplies</td>
                        <td>Regional Farms</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Energy</td>
                        <td>Regional Grid</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Building Materials</td>
                        <td>National</td>
                        <td>
                          <span className="risk-indicator risk-low"></span>
                          Low
                        </td>
                      </tr>
                    </>
                  )}
                  
                  {asset.type === 'Agriculture' && (
                    <>
                      <tr>
                        <td>Irrigation Water</td>
                        <td>Local Watershed</td>
                        <td>
                          <span className="risk-indicator risk-high"></span>
                          High
                        </td>
                      </tr>
                      <tr>
                        <td>Fertilizers</td>
                        <td>International</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Seasonal Labor</td>
                        <td>Regional</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Equipment Parts</td>
                        <td>National</td>
                        <td>
                          <span className="risk-indicator risk-low"></span>
                          Low
                        </td>
                      </tr>
                    </>
                  )}
                  
                  {asset.type === 'Commercial Real Estate' && (
                    <>
                      <tr>
                        <td>Electricity</td>
                        <td>Regional Grid</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>HVAC Equipment</td>
                        <td>International</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Water</td>
                        <td>Municipal Supply</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Building Materials</td>
                        <td>National</td>
                        <td>
                          <span className="risk-indicator risk-low"></span>
                          Low
                        </td>
                      </tr>
                    </>
                  )}
                  
                  {asset.type === 'Residential' && (
                    <>
                      <tr>
                        <td>Coastal Protection</td>
                        <td>Local Ecosystem</td>
                        <td>
                          <span className="risk-indicator risk-high"></span>
                          High
                        </td>
                      </tr>
                      <tr>
                        <td>Electricity</td>
                        <td>Regional Grid</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Water</td>
                        <td>Municipal Supply</td>
                        <td>
                          <span className="risk-indicator risk-medium"></span>
                          Medium
                        </td>
                      </tr>
                      <tr>
                        <td>Building Materials</td>
                        <td>National</td>
                        <td>
                          <span className="risk-indicator risk-low"></span>
                          Low
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          
          <div className="regulatory-table">
            <h3>Regulatory Compliance Status</h3>
            <table>
              <thead>
                <tr>
                  <th>Regulation</th>
                  <th>Description</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EU CSRD</td>
                  <td>Corporate Sustainability Reporting Directive</td>
                  <td>
                    <span className="status compliant">Compliant</span>
                  </td>
                </tr>
                <tr>
                  <td>EU CSDDD</td>
                  <td>Corporate Sustainability Due Diligence Directive</td>
                  <td>
                    <span className="status at-risk">At Risk</span>
                  </td>
                </tr>
                <tr>
                  <td>SEC Climate Rule</td>
                  <td>SEC Climate-Related Disclosure</td>
                  <td>
                    <span className="status compliant">Compliant</span>
                  </td>
                </tr>
                <tr>
                  <td>TNFD Framework</td>
                  <td>Taskforce on Nature-related Financial Disclosures</td>
                  <td>
                    <span className="status non-compliant">Non-Compliant</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </SupplyChainSection>
        
        <SectionHeader id="riskHistory">Risk Assessment History</SectionHeader>
        
        {assessments.length > 0 ? (
          <AssessmentGrid>
            {assessments.map(assessment => (
              <AssessmentCard 
                key={assessment.id} 
                to={`/risk-assessment-detail/${assessment.id}`}
                $score={assessment.overallScore}
              >
                <h3>Risk Assessment</h3>
                <div className="date">
                  <FiCalendar /> {new Date(assessment.date).toLocaleDateString()}
                </div>
                <div className="risk-gauge">
                  <div className="score-circle">
                    <div className="score-value">{assessment.overallScore}</div>
                  </div>
                </div>
              </AssessmentCard>
            ))}
          </AssessmentGrid>
        ) : (
          <DetailSection>
            <p>No risk assessments have been performed for this asset yet.</p>
            <ActionButton to={`/risk-assessment/${asset.id}`} $primary>
              <FiAlertTriangle /> Perform Risk Assessment
            </ActionButton>
          </DetailSection>
        )}
        
        <SectionHeader>Historical Risk Trend</SectionHeader>
        {renderRiskHistoryChart()}
      </DetailGrid>
    </AssetDetailContainer>
  );
};

export default AssetDetail; 