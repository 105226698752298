import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 1rem 1.5rem;
  background-color: #1f2937;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 0.875rem;
  
  p {
    margin: 0;
  }
  
  a {
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>© {new Date().getFullYear()} Mandjet. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
