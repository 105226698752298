import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiPlus, FiFilter, FiSearch, FiMap, FiList, FiChevronUp, FiChevronDown, FiGrid, FiFolder, FiMapPin, FiDollarSign, FiAlertTriangle, FiDroplet } from 'react-icons/fi';
import { assets } from '../data/mockData';

// Styled components
const AssetListContainer = styled.div`
  padding: 1rem 0;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const ActionButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  color: white;
  font-weight: 500;
  border-radius: 0.375rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--primary-dark);
  }
`;

const SearchFilterBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchBar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  padding: 0 0.75rem;
  
  input {
    flex: 1;
    border: none;
    padding: 0.625rem 0;
    outline: none;
  }
  
  svg {
    color: var(--text-light);
  }
`;

const FilterDropdown = styled.div`
  position: relative;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  background-color: white;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
  
  &:hover {
    background-color: var(--background);
  }
`;

const FilterMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  z-index: 10;
  overflow: hidden;
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

const FilterSection = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  
  &:last-child {
    border-bottom: none;
  }
  
  h3 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  
  input {
    cursor: pointer;
  }
`;

const ViewToggle = styled.div`
  display: flex;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  overflow: hidden;
`;

const ViewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  background-color: ${props => props.$active ? 'var(--primary)' : 'white'};
  color: ${props => props.$active ? 'white' : 'var(--text)'};
  border: none;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.$active ? 'var(--primary)' : 'var(--background)'};
  }
`;

const AssetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const AssetCard = styled(Link)`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  text-decoration: none;
  color: var(--text);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .meta {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .meta svg {
    flex-shrink: 0;
  }
  
  .description {
    font-size: 0.85rem;
    margin-bottom: 1rem;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .value {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .risk-indicator {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    
    .risk-bar {
      flex: 1;
      height: 8px;
      background-color: #e5e7eb;
      border-radius: 4px;
      overflow: hidden;
      
      .risk-level {
        height: 100%;
        border-radius: 4px;
      }
    }
    
    .risk-score {
      font-weight: 600;
      margin-left: 1rem;
    }
  }
  
  .risk-factors {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
  
  .risk-tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--text-light);
    white-space: nowrap;
  }
  
  .ecosystem-dependencies {
    margin-top: 0.75rem;
    font-size: 0.85rem;
    color: var(--text);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .critical-badge {
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.15rem 0.4rem;
    border-radius: 0.25rem;
    background-color: rgba(239, 68, 68, 0.1);
    color: var(--error);
  }
`;

const AssetTable = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 1.5rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid var(--border);
  }
  
  th {
    font-weight: 600;
    color: var(--text-light);
    font-size: 0.9rem;
    background-color: var(--background);
    cursor: pointer;
    user-select: none;
  }
  
  tbody tr {
    &:hover {
      background-color: var(--background);
    }
  }
  
  td {
    vertical-align: middle;
  }
`;

const SortIcon = styled.span`
  display: inline-flex;
  margin-left: 0.25rem;
  color: var(--text-light);
`;

const RiskBadge = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: ${props => 
    props.$level === 'high' ? 'rgba(239, 68, 68, 0.1)' : 
    props.$level === 'medium' ? 'rgba(245, 158, 11, 0.1)' : 
    'rgba(16, 185, 129, 0.1)'
  };
  color: ${props => 
    props.$level === 'high' ? 'var(--error)' : 
    props.$level === 'medium' ? 'var(--warning)' : 
    'var(--success)'
  };
`;

const AssetLink = styled(Link)`
  font-weight: 500;
  color: var(--text);
  text-decoration: none;
  
  &:hover {
    color: var(--primary);
  }
`;

const AssetStatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${props => 
    props.$status === 'active' ? 'var(--success)' : 
    props.$status === 'warning' ? 'var(--warning)' : 
    props.$status === 'critical' ? 'var(--error)' : 
    'var(--text-light)'
  };
`;

const ValueRangeSlider = styled.div`
  padding: 0 0.5rem;
  
  .slider-container {
    margin: 1rem 0;
  }
  
  .range-values {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  
  input[type="range"] {
    width: 100%;
  }
`;

const ListStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  
  .stat-title {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-bottom: 0.5rem;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .stat-value svg {
    color: var(--primary);
  }
  
  .stat-comparison {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    
    &.positive {
      color: var(--success);
    }
    
    &.negative {
      color: var(--error);
    }
  }
`;

const QuickActionBar = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const QuickAction = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: var(--background);
    border-color: var(--primary);
    color: var(--primary);
  }
  
  svg {
    color: var(--text-light);
  }
  
  &:hover svg {
    color: var(--primary);
  }
`;

const CategoryLabel = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  margin-right: 0.5rem;
  background-color: ${props => 
    props.$type === 'Property' ? 'rgba(59, 130, 246, 0.1)' : 
    props.$type === 'Land' ? 'rgba(16, 185, 129, 0.1)' : 
    props.$type === 'Infrastructure' ? 'rgba(245, 158, 11, 0.1)' :
    props.$type === 'Facility' ? 'rgba(139, 92, 246, 0.1)' :
    'rgba(107, 114, 128, 0.1)'
  };
  color: ${props => 
    props.$type === 'Property' ? '#3b82f6' : 
    props.$type === 'Land' ? '#10b981' : 
    props.$type === 'Infrastructure' ? '#f59e0b' :
    props.$type === 'Facility' ? '#8b5cf6' :
    '#6b7280'
  };
`;

const AssetList = () => {
  // State variables
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [assetTypeFilters, setAssetTypeFilters] = useState([]);
  const [riskLevelFilters, setRiskLevelFilters] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [valueRange, setValueRange] = useState([0, 20]); // In millions
  const [statusFilters, setStatusFilters] = useState([]);
  const [filters, setFilters] = useState({
    assetType: [],
    riskLevel: [],
    ecosystem: []
  });
  
  // Get unique asset types for filter
  const assetTypes = [...new Set(assets.map(asset => asset.type))];
  
  // Apply filters and sorting
  useEffect(() => {
    let filtered = [...assets];
    
    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(asset => 
        asset.name.toLowerCase().includes(query) || 
        asset.description.toLowerCase().includes(query) ||
        asset.type.toLowerCase().includes(query)
      );
    }
    
    // Apply asset type filters
    if (assetTypeFilters.length > 0) {
      filtered = filtered.filter(asset => assetTypeFilters.includes(asset.type));
    }
    
    // Apply risk level filters
    if (riskLevelFilters.length > 0) {
      filtered = filtered.filter(asset => riskLevelFilters.includes(asset.risk));
    }
    
    // Apply value range filter
    filtered = filtered.filter(asset => {
      const valueInMillions = asset.value / 1000000;
      return valueInMillions >= valueRange[0] && valueInMillions <= valueRange[1];
    });
    
    // Apply status filters
    if (statusFilters.length > 0) {
      filtered = filtered.filter(asset => statusFilters.includes(asset.status));
    }
    
    // Apply ecosystem filters
    if (filters.ecosystem.length > 0) {
      filtered = filtered.filter(asset => {
        if (!asset.ecosystemDependencies) return false;
        
        const dependencyTypes = asset.ecosystemDependencies.map(dep => dep.type.toLowerCase());
        
        return filters.ecosystem.some(ecoType => 
          dependencyTypes.some(depType => depType.includes(ecoType))
        );
      });
    }
    
    // Apply sorting
    filtered.sort((a, b) => {
      let valueA, valueB;
      
      switch (sortField) {
        case 'name':
          valueA = a.name;
          valueB = b.name;
          break;
        case 'type':
          valueA = a.type;
          valueB = b.type;
          break;
        case 'risk':
          valueA = a.riskScore;
          valueB = b.riskScore;
          break;
        case 'value':
          valueA = a.value;
          valueB = b.value;
          break;
        default:
          valueA = a.name;
          valueB = b.name;
      }
      
      if (typeof valueA === 'string') {
        const comparison = valueA.localeCompare(valueB);
        return sortDirection === 'asc' ? comparison : -comparison;
      } else {
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      }
    });
    
    setFilteredAssets(filtered);
  }, [searchQuery, assetTypeFilters, riskLevelFilters, sortField, sortDirection, valueRange, statusFilters, filters.ecosystem]);
  
  // Toggle filter dropdown
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  
  // Handle asset type filter change
  const handleAssetTypeFilter = (type) => {
    if (assetTypeFilters.includes(type)) {
      setAssetTypeFilters(assetTypeFilters.filter(t => t !== type));
    } else {
      setAssetTypeFilters([...assetTypeFilters, type]);
    }
  };
  
  // Handle risk level filter change
  const handleRiskLevelFilter = (level) => {
    if (riskLevelFilters.includes(level)) {
      setRiskLevelFilters(riskLevelFilters.filter(l => l !== level));
    } else {
      setRiskLevelFilters([...riskLevelFilters, level]);
    }
  };
  
  // Handle table header click for sorting
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle direction if same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Render sort icon
  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    
    return (
      <SortIcon>
        {sortDirection === 'asc' ? <FiChevronUp /> : <FiChevronDown />}
      </SortIcon>
    );
  };
  
  // Handle ecosystem filter change
  const handleEcosystemFilter = (ecosystemType) => {
    setFilters(prevFilters => {
      const ecosystem = prevFilters.ecosystem || [];
      if (ecosystem.includes(ecosystemType)) {
        return {
          ...prevFilters,
          ecosystem: ecosystem.filter(type => type !== ecosystemType)
        };
      } else {
        return {
          ...prevFilters,
          ecosystem: [...ecosystem, ecosystemType]
        };
      }
    });
  };
  
  return (
    <AssetListContainer>
      <ListHeader>
        <div>
          <Title>Climate Risk Assets</Title>
          <Description>
            Manage your portfolio of assets with climate-exposed ecosystem dependencies. View detailed risk profiles 
            for each asset and understand their natural capital vulnerabilities and adaptation opportunities.
          </Description>
        </div>
        <ActionButton to="/add-asset">
          <FiPlus /> Add New Asset
        </ActionButton>
      </ListHeader>
      
      <ListStats>
        <StatCard>
          <div className="stat-title">Total Assets</div>
          <div className="stat-value">
            <FiGrid /> {filteredAssets.length}
          </div>
          {assets.length !== filteredAssets.length && (
            <div className="stat-comparison">
              of {assets.length} total
            </div>
          )}
        </StatCard>
        <StatCard>
          <div className="stat-title">Total Portfolio Value</div>
          <div className="stat-value">
            <FiDollarSign /> ${(filteredAssets.reduce((sum, asset) => sum + asset.value, 0) / 1000000).toFixed(1)}M
          </div>
        </StatCard>
        <StatCard>
          <div className="stat-title">High Risk Assets</div>
          <div className="stat-value">
            <FiAlertTriangle /> {filteredAssets.filter(asset => asset.risk === 'high').length}
          </div>
          <div className="stat-comparison negative">
            {Math.round(filteredAssets.filter(asset => asset.risk === 'high').length / filteredAssets.length * 100)}% of portfolio
          </div>
        </StatCard>
      </ListStats>
      
      <QuickActionBar>
        <QuickAction onClick={() => setViewMode('grid')}>
          <FiGrid /> Grid View
        </QuickAction>
        <QuickAction onClick={() => setViewMode('list')}>
          <FiList /> List View
        </QuickAction>
        <QuickAction onClick={() => setRiskLevelFilters(['high'])}>
          <FiAlertTriangle /> High Risk Only
        </QuickAction>
        <QuickAction onClick={() => setRiskLevelFilters([])}>
          <FiFilter /> Clear Filters
        </QuickAction>
      </QuickActionBar>
      
      <SearchFilterBar>
        <SearchBar>
          <FiSearch />
          <input
            type="text"
            placeholder="Search assets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchBar>
        
        <FilterDropdown>
          <FilterButton onClick={toggleFilter}>
            <FiFilter /> Filters
          </FilterButton>
          
          <FilterMenu $isOpen={isFilterOpen}>
            <FilterSection>
              <h3>Asset Type</h3>
              <FilterOptions>
                {assetTypes.map(type => (
                  <FilterOption key={type}>
                    <input
                      type="checkbox"
                      id={`type-${type}`}
                      checked={assetTypeFilters.includes(type)}
                      onChange={() => handleAssetTypeFilter(type)}
                    />
                    <span>{type}</span>
                  </FilterOption>
                ))}
              </FilterOptions>
            </FilterSection>
            
            <FilterSection>
              <h3>Risk Level</h3>
              <FilterOptions>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="risk-high"
                    checked={riskLevelFilters.includes('high')}
                    onChange={() => handleRiskLevelFilter('high')}
                  />
                  <span>High Risk</span>
                </FilterOption>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="risk-medium"
                    checked={riskLevelFilters.includes('medium')}
                    onChange={() => handleRiskLevelFilter('medium')}
                  />
                  <span>Medium Risk</span>
                </FilterOption>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="risk-low"
                    checked={riskLevelFilters.includes('low')}
                    onChange={() => handleRiskLevelFilter('low')}
                  />
                  <span>Low Risk</span>
                </FilterOption>
              </FilterOptions>
            </FilterSection>
            
            <FilterSection>
              <h3>Asset Value (in millions)</h3>
              <ValueRangeSlider>
                <div className="range-values">
                  <span>${valueRange[0]}M</span>
                  <span>${valueRange[1]}M</span>
                </div>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0"
                    max="20"
                    step="1"
                    value={valueRange[1]}
                    onChange={(e) => setValueRange([valueRange[0], parseInt(e.target.value)])}
                  />
                </div>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0"
                    max="20"
                    step="1"
                    value={valueRange[0]}
                    onChange={(e) => setValueRange([parseInt(e.target.value), valueRange[1]])}
                  />
                </div>
              </ValueRangeSlider>
            </FilterSection>
            
            <FilterSection>
              <h3>Status</h3>
              <FilterOptions>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="status-active"
                    checked={statusFilters.includes('active')}
                    onChange={() => {
                      if (statusFilters.includes('active')) {
                        setStatusFilters(statusFilters.filter(s => s !== 'active'));
                      } else {
                        setStatusFilters([...statusFilters, 'active']);
                      }
                    }}
                  />
                  <span>Active</span>
                </FilterOption>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="status-warning"
                    checked={statusFilters.includes('warning')}
                    onChange={() => {
                      if (statusFilters.includes('warning')) {
                        setStatusFilters(statusFilters.filter(s => s !== 'warning'));
                      } else {
                        setStatusFilters([...statusFilters, 'warning']);
                      }
                    }}
                  />
                  <span>Warning</span>
                </FilterOption>
                <FilterOption>
                  <input
                    type="checkbox"
                    id="status-critical"
                    checked={statusFilters.includes('critical')}
                    onChange={() => {
                      if (statusFilters.includes('critical')) {
                        setStatusFilters(statusFilters.filter(s => s !== 'critical'));
                      } else {
                        setStatusFilters([...statusFilters, 'critical']);
                      }
                    }}
                  />
                  <span>Critical</span>
                </FilterOption>
              </FilterOptions>
            </FilterSection>
            
            <FilterSection>
              <h3>Ecosystem Dependencies</h3>
              <FilterOptions>
                <FilterOption>
                  <input 
                    type="checkbox" 
                    id="eco-water" 
                    checked={filters.ecosystem?.includes('water')}
                    onChange={() => handleEcosystemFilter('water')}
                  />
                  <span>Water Resources</span>
                </FilterOption>
                <FilterOption>
                  <input 
                    type="checkbox" 
                    id="eco-forest" 
                    checked={filters.ecosystem?.includes('forest')}
                    onChange={() => handleEcosystemFilter('forest')}
                  />
                  <span>Forest Ecosystems</span>
                </FilterOption>
                <FilterOption>
                  <input 
                    type="checkbox" 
                    id="eco-coastal" 
                    checked={filters.ecosystem?.includes('coastal')}
                    onChange={() => handleEcosystemFilter('coastal')}
                  />
                  <span>Coastal Protection</span>
                </FilterOption>
                <FilterOption>
                  <input 
                    type="checkbox" 
                    id="eco-pollination" 
                    checked={filters.ecosystem?.includes('pollination')}
                    onChange={() => handleEcosystemFilter('pollination')}
                  />
                  <span>Pollination Services</span>
                </FilterOption>
              </FilterOptions>
            </FilterSection>
          </FilterMenu>
        </FilterDropdown>
        
        <ViewToggle>
          <ViewButton
            $active={viewMode === 'grid'}
            onClick={() => setViewMode('grid')}
          >
            <FiGrid />
          </ViewButton>
          <ViewButton
            $active={viewMode === 'list'}
            onClick={() => setViewMode('list')}
          >
            <FiList />
          </ViewButton>
        </ViewToggle>
      </SearchFilterBar>
      
      {viewMode === 'grid' ? (
        <AssetGrid>
          {filteredAssets.map(asset => (
            <AssetCard 
              key={asset.id} 
              to={`/asset/${asset.id}`}
              onClick={() => console.log(`Clicked asset ${asset.id}: ${asset.name}`)}
            >
              <h3>
                {asset.status && <AssetStatusIndicator $status={asset.status || 'active'} />}
                {asset.name}
                <RiskBadge $level={asset.risk}>
                  {asset.risk.toUpperCase()}
                </RiskBadge>
              </h3>
              <div className="meta">
                <CategoryLabel $type={asset.type}>{asset.type}</CategoryLabel>
              </div>
              <div className="meta">
                <FiMapPin /> {asset.location}
              </div>
              <div className="description">{asset.description}</div>
              <div className="value">
                <FiDollarSign size={16} /> ${(asset.value / 1000000).toFixed(1)}M
              </div>
              <div className="risk-indicator">
                <div className="risk-bar">
                  <div 
                    className="risk-level" 
                    style={{
                      width: `${asset.riskScore}%`,
                      backgroundColor: 
                        asset.riskScore > 70 ? 'var(--error)' : 
                        asset.riskScore > 40 ? 'var(--warning)' : 
                        'var(--success)'
                    }}
                  />
                </div>
                <div className="risk-score">{asset.riskScore}</div>
              </div>
              
              {asset.ecosystemDependencies && (
                <div className="ecosystem-dependencies">
                  <FiDroplet size={14} /> 
                  {asset.ecosystemDependencies.length} ecosystem dependencies
                  {asset.ecosystemDependencies.some(dep => dep.risk === 'critical') && (
                    <span className="critical-badge">Critical</span>
                  )}
                </div>
              )}
            </AssetCard>
          ))}
        </AssetGrid>
      ) : (
        <AssetTable>
          <Table>
            <thead>
              <tr>
                <th onClick={() => handleSort('name')}>
                  Name {renderSortIcon('name')}
                </th>
                <th onClick={() => handleSort('type')}>
                  Type {renderSortIcon('type')}
                </th>
                <th>Location</th>
                <th onClick={() => handleSort('risk')}>
                  Risk {renderSortIcon('risk')}
                </th>
                <th onClick={() => handleSort('value')}>
                  Value {renderSortIcon('value')}
                </th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {filteredAssets.map(asset => (
                <tr key={asset.id}>
                  <td>
                    <AssetLink to={`/asset/${asset.id}`}>
                      {asset.status && <AssetStatusIndicator $status={asset.status || 'active'} />}
                      {asset.name}
                    </AssetLink>
                  </td>
                  <td>
                    <CategoryLabel $type={asset.type}>{asset.type}</CategoryLabel>
                  </td>
                  <td>{asset.location}</td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <RiskBadge $level={asset.risk}>
                        {asset.risk.toUpperCase()}
                      </RiskBadge>
                      <div style={{ 
                        width: '50px', 
                        height: '8px', 
                        backgroundColor: '#e5e7eb', 
                        borderRadius: '4px',
                        overflow: 'hidden'
                      }}>
                        <div style={{
                          width: `${asset.riskScore}%`,
                          height: '100%',
                          backgroundColor: 
                            asset.riskScore > 70 ? 'var(--error)' : 
                            asset.riskScore > 40 ? 'var(--warning)' : 
                            'var(--success)',
                          borderRadius: '4px'
                        }}></div>
                      </div>
                      <span>{asset.riskScore}</span>
                    </div>
                  </td>
                  <td>${(asset.value / 1000000).toFixed(1)}M</td>
                  <td style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {asset.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </AssetTable>
      )}
    </AssetListContainer>
  );
};

export default AssetList; 