import React, { useState } from 'react';
import styled from 'styled-components';
import { 
  FiUser, 
  FiLock, 
  FiBell, 
  FiGlobe, 
  FiSettings as FiSettingsIcon, 
  FiSave,
  FiChevronRight
} from 'react-icons/fi';

// Styled components
const SettingsContainer = styled.div`
  padding: 1rem 0;
`;

const SettingsHeader = styled.div`
  margin-bottom: 1.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const SettingsLayout = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SettingsNav = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const NavItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  text-align: left;
  background: none;
  border: none;
  border-bottom: 1px solid var(--border-light);
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:last-child {
    border-bottom: none;
  }
  
  .icon {
    margin-right: 0.75rem;
    color: ${props => props.$active ? 'var(--primary)' : 'var(--text-light)'};
  }
  
  .content {
    flex: 1;
  }
  
  .title {
    color: ${props => props.$active ? 'var(--primary)' : 'var(--text)'};
    font-weight: ${props => props.$active ? '600' : '500'};
    margin: 0;
    font-size: 0.95rem;
  }
  
  .description {
    color: var(--text-light);
    font-size: 0.8rem;
    margin: 0.25rem 0 0;
  }
  
  .arrow {
    color: ${props => props.$active ? 'var(--primary)' : 'var(--text-light)'};
  }
  
  &:hover {
    background-color: var(--background-light);
  }
  
  &:focus {
    outline: none;
    background-color: var(--background-light);
  }
`;

const SettingsPanel = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const PanelTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 1.5rem;
`;

const FormSection = styled.div`
  margin-bottom: 2rem;
  
  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-light);
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.25rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  input, select, textarea {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.9rem;
    
    &:focus {
      outline: none;
      border-color: var(--primary);
      box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.2);
    }
    
    &:disabled {
      background-color: var(--background-light);
      cursor: not-allowed;
    }
  }
  
  textarea {
    min-height: 100px;
  }
  
  .help-text {
    margin-top: 0.375rem;
    font-size: 0.8rem;
    color: var(--text-light);
  }
`;

const SwitchGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.875rem;
  padding: 0.625rem 0;
  
  .label-group {
    .label {
      font-weight: 500;
      font-size: 0.9rem;
    }
    
    .description {
      font-size: 0.8rem;
      color: var(--text-light);
      margin-top: 0.25rem;
    }
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  
  input {
    opacity: 0;
    width: 0;
    height: 0;
    
    &:checked + .slider {
      background-color: var(--primary);
    }
    
    &:checked + .slider:before {
      transform: translateX(20px);
    }
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
    
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  
  button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 0.375rem;
    cursor: pointer;
    
    &.cancel {
      background-color: white;
      border: 1px solid var(--border);
      color: var(--text);
      margin-right: 0.75rem;
      
      &:hover {
        background-color: var(--background-light);
      }
    }
    
    &.save {
      background-color: var(--primary);
      border: 1px solid var(--primary);
      color: white;
      
      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }
`;

const Settings = () => {
  const [activeSection, setActiveSection] = useState('account');
  
  const [accountSettings, setAccountSettings] = useState({
    email: 'user@example.com',
    password: '********',
    twoFactorAuth: true
  });
  
  const [profileSettings, setProfileSettings] = useState({
    name: 'John Doe',
    title: 'Risk Manager',
    company: 'Alpine Eco Solutions',
    bio: 'Experienced risk manager with a focus on environmental and climate risks.',
    timeZone: 'UTC-8'
  });
  
  const [notificationSettings, setNotificationSettings] = useState({
    emailUpdates: true,
    reportCompletions: true,
    riskAlerts: true,
    assetModifications: false,
    teamActivity: true,
    marketingUpdates: false
  });
  
  const handleSwitchChange = (section, field) => {
    if (section === 'account') {
      setAccountSettings({
        ...accountSettings,
        [field]: !accountSettings[field]
      });
    } else if (section === 'notifications') {
      setNotificationSettings({
        ...notificationSettings,
        [field]: !notificationSettings[field]
      });
    }
  };
  
  const renderNavItems = () => {
    const items = [
      {
        id: 'account',
        icon: <FiUser className="icon" />,
        title: 'Account Settings',
        description: 'Manage your account details and security'
      },
      {
        id: 'profile',
        icon: <FiUser className="icon" />,
        title: 'Profile Information',
        description: 'Update your profile and preferences'
      },
      {
        id: 'notifications',
        icon: <FiBell className="icon" />,
        title: 'Notifications',
        description: 'Configure how you receive alerts'
      },
      {
        id: 'system',
        icon: <FiSettingsIcon className="icon" />,
        title: 'System Settings',
        description: 'Configure application settings'
      }
    ];
    
    return items.map(item => (
      <NavItem 
        key={item.id}
        $active={activeSection === item.id}
        onClick={() => setActiveSection(item.id)}
      >
        {item.icon}
        <div className="content">
          <div className="title">{item.title}</div>
          <div className="description">{item.description}</div>
        </div>
        <FiChevronRight className="arrow" />
      </NavItem>
    ));
  };
  
  const renderActivePanel = () => {
    switch (activeSection) {
      case 'account':
        return (
          <>
            <PanelTitle>Account Settings</PanelTitle>
            
            <FormSection>
              <h3>Login Information</h3>
              <FormGroup>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  value={accountSettings.email}
                  onChange={() => {}}
                />
                <div className="help-text">We'll never share your email with anyone else.</div>
              </FormGroup>
              
              <FormGroup>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={accountSettings.password}
                  onChange={() => {}}
                  disabled
                />
                <div className="help-text">
                  <a href="#" style={{ color: 'var(--primary)', textDecoration: 'none' }}>
                    Change password
                  </a>
                </div>
              </FormGroup>
            </FormSection>
            
            <FormSection>
              <h3>Security</h3>
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Two-factor authentication</div>
                  <div className="description">Require a code in addition to your password</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={accountSettings.twoFactorAuth}
                    onChange={() => handleSwitchChange('account', 'twoFactorAuth')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
            </FormSection>
            
            <ButtonGroup>
              <button className="cancel">Cancel</button>
              <button className="save">
                <FiSave /> Save Changes
              </button>
            </ButtonGroup>
          </>
        );
        
      case 'profile':
        return (
          <>
            <PanelTitle>Profile Information</PanelTitle>
            
            <FormSection>
              <h3>Personal Information</h3>
              <FormGroup>
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  value={profileSettings.name}
                  onChange={() => {}}
                />
              </FormGroup>
              
              <FormGroup>
                <label htmlFor="title">Job Title</label>
                <input
                  type="text"
                  id="title"
                  value={profileSettings.title}
                  onChange={() => {}}
                />
              </FormGroup>
              
              <FormGroup>
                <label htmlFor="company">Organization</label>
                <input
                  type="text"
                  id="company"
                  value={profileSettings.company}
                  onChange={() => {}}
                />
              </FormGroup>
              
              <FormGroup>
                <label htmlFor="bio">Bio</label>
                <textarea
                  id="bio"
                  value={profileSettings.bio}
                  onChange={() => {}}
                />
                <div className="help-text">Brief description visible to your team members</div>
              </FormGroup>
            </FormSection>
            
            <FormSection>
              <h3>Regional Settings</h3>
              <FormGroup>
                <label htmlFor="timezone">Time Zone</label>
                <select
                  id="timezone"
                  value={profileSettings.timeZone}
                  onChange={() => {}}
                >
                  <option value="UTC-12">UTC-12:00</option>
                  <option value="UTC-11">UTC-11:00</option>
                  <option value="UTC-10">UTC-10:00</option>
                  <option value="UTC-9">UTC-09:00</option>
                  <option value="UTC-8">UTC-08:00</option>
                  <option value="UTC-7">UTC-07:00</option>
                  <option value="UTC-6">UTC-06:00</option>
                  <option value="UTC-5">UTC-05:00</option>
                  <option value="UTC-4">UTC-04:00</option>
                  <option value="UTC-3">UTC-03:00</option>
                  <option value="UTC-2">UTC-02:00</option>
                  <option value="UTC-1">UTC-01:00</option>
                  <option value="UTC+0">UTC+00:00</option>
                  <option value="UTC+1">UTC+01:00</option>
                  <option value="UTC+2">UTC+02:00</option>
                  <option value="UTC+3">UTC+03:00</option>
                  <option value="UTC+4">UTC+04:00</option>
                  <option value="UTC+5">UTC+05:00</option>
                  <option value="UTC+6">UTC+06:00</option>
                  <option value="UTC+7">UTC+07:00</option>
                  <option value="UTC+8">UTC+08:00</option>
                  <option value="UTC+9">UTC+09:00</option>
                  <option value="UTC+10">UTC+10:00</option>
                  <option value="UTC+11">UTC+11:00</option>
                  <option value="UTC+12">UTC+12:00</option>
                </select>
              </FormGroup>
            </FormSection>
            
            <ButtonGroup>
              <button className="cancel">Cancel</button>
              <button className="save">
                <FiSave /> Save Changes
              </button>
            </ButtonGroup>
          </>
        );
        
      case 'notifications':
        return (
          <>
            <PanelTitle>Notification Preferences</PanelTitle>
            
            <FormSection>
              <h3>Email Notifications</h3>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">System Updates</div>
                  <div className="description">Receive emails about system updates and maintenance</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.emailUpdates}
                    onChange={() => handleSwitchChange('notifications', 'emailUpdates')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Report Completions</div>
                  <div className="description">Notify when a report generation is complete</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.reportCompletions}
                    onChange={() => handleSwitchChange('notifications', 'reportCompletions')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Risk Alerts</div>
                  <div className="description">Get notified about important risk changes</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.riskAlerts}
                    onChange={() => handleSwitchChange('notifications', 'riskAlerts')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Asset Modifications</div>
                  <div className="description">Alerts when assets are added, edited, or removed</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.assetModifications}
                    onChange={() => handleSwitchChange('notifications', 'assetModifications')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Team Activity</div>
                  <div className="description">Updates about team member actions</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.teamActivity}
                    onChange={() => handleSwitchChange('notifications', 'teamActivity')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
              
              <SwitchGroup>
                <div className="label-group">
                  <div className="label">Marketing Updates</div>
                  <div className="description">Receive news and product updates</div>
                </div>
                <Switch>
                  <input
                    type="checkbox"
                    checked={notificationSettings.marketingUpdates}
                    onChange={() => handleSwitchChange('notifications', 'marketingUpdates')}
                  />
                  <span className="slider"></span>
                </Switch>
              </SwitchGroup>
            </FormSection>
            
            <ButtonGroup>
              <button className="cancel">Cancel</button>
              <button className="save">
                <FiSave /> Save Changes
              </button>
            </ButtonGroup>
          </>
        );
        
      case 'system':
        return (
          <>
            <PanelTitle>System Settings</PanelTitle>
            
            <FormSection>
              <h3>Display Settings</h3>
              
              <FormGroup>
                <label htmlFor="language">Language</label>
                <select
                  id="language"
                  defaultValue="en"
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  <option value="de">German</option>
                  <option value="zh">Chinese</option>
                </select>
              </FormGroup>
              
              <FormGroup>
                <label>Date Format</label>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input type="radio" name="dateFormat" defaultChecked />
                    <span>MM/DD/YYYY</span>
                  </label>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input type="radio" name="dateFormat" />
                    <span>DD/MM/YYYY</span>
                  </label>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input type="radio" name="dateFormat" />
                    <span>YYYY-MM-DD</span>
                  </label>
                </div>
              </FormGroup>
            </FormSection>
            
            <FormSection>
              <h3>API Access</h3>
              <FormGroup>
                <label htmlFor="apiKey">API Key</label>
                <div style={{ display: 'flex' }}>
                  <input
                    type="password"
                    id="apiKey"
                    value="************************"
                    disabled
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  />
                  <button
                    style={{
                      padding: '0.625rem 0.75rem',
                      backgroundColor: 'var(--secondary)',
                      color: 'white',
                      border: 'none',
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem',
                      cursor: 'pointer'
                    }}
                  >
                    Regenerate
                  </button>
                </div>
                <div className="help-text">Use this key to access our API. Keep it secure!</div>
              </FormGroup>
            </FormSection>
            
            <ButtonGroup>
              <button className="cancel">Cancel</button>
              <button className="save">
                <FiSave /> Save Changes
              </button>
            </ButtonGroup>
          </>
        );
        
      default:
        return null;
    }
  };
  
  return (
    <SettingsContainer>
      <SettingsHeader>
        <Title>Settings</Title>
        <Description>
          Manage your account settings and preferences
        </Description>
      </SettingsHeader>
      
      <SettingsLayout>
        <SettingsNav>
          {renderNavItems()}
        </SettingsNav>
        
        <SettingsPanel>
          {renderActivePanel()}
        </SettingsPanel>
      </SettingsLayout>
    </SettingsContainer>
  );
};

export default Settings; 