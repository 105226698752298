import React, { useState } from 'react';
import styled from 'styled-components';
import { FiBarChart2, FiPieChart, FiTrendingUp, FiCalendar, FiRefreshCw, FiAlertTriangle, FiDollarSign } from 'react-icons/fi';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import { assets } from '../data/mockData';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  ChartTitle,
  Tooltip,
  Legend
);

// Styled components
const AnalyticsContainer = styled.div`
  padding: 1rem 0;
`;

const AnalyticsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const Controls = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const SelectControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  select {
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    background-color: white;
    font-size: 0.875rem;
  }
`;

const RefreshButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  background-color: white;
  color: var(--text);
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  
  &:hover {
    background-color: var(--background);
  }
`;

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const ChartCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .icon-button {
    background: none;
    border: none;
    color: var(--text-light);
    cursor: pointer;
    padding: 0.25rem;
    
    &:hover {
      color: var(--text);
    }
  }
`;

const ChartDescription = styled.p`
  margin: 0 0 1rem;
  color: var(--text-light);
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ChartContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
`;

const FullWidthCard = styled(ChartCard)`
  grid-column: 1 / -1;
  height: auto;
`;

const TopAssetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const AssetItem = styled.div`
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: 0.5rem;
  
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  p {
    font-size: 0.875rem;
    color: var(--text-light);
    margin: 0 0 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ProgressCircle = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background: conic-gradient(
    var(--primary) 0% ${props => props.$percentage}%,
    #E2E8F0 ${props => props.$percentage}% 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::after {
    content: '${props => props.$percentage}%';
    position: absolute;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const KpiRow = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const KpiCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  flex: 1;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 0.5rem;
    background-color: ${props => `var(--${props.$color}-light)`};
    color: ${props => `var(--${props.$color})`};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 0.25rem;
  }
  
  p {
    font-size: 0.875rem;
    color: var(--text-light);
    margin: 0;
  }
  
  .trend {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${props => props.$trend > 0 ? 'var(--success)' : 'var(--error)'};
  }
`;

const Analytics = () => {
  const [timeRange, setTimeRange] = useState('1y');
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Calculate totals from assets data
  const totalAssetValue = assets.reduce((sum, asset) => sum + asset.value, 0);
  const atRiskValue = assets
    .filter(asset => asset.risk === 'high' || asset.risk === 'critical')
    .reduce((sum, asset) => sum + asset.value, 0);
  const totalEcosystemDependencies = assets.reduce(
    (sum, asset) => sum + asset.ecosystemDependencies.length, 0
  );
  
  const refreshData = () => {
    setIsRefreshing(true);
    setTimeout(() => setIsRefreshing(false), 1000);
  };
  
  return (
    <AnalyticsContainer>
      <AnalyticsHeader>
        <div>
          <PageTitle>Analytics</PageTitle>
          <Description>
            Advanced analytics and insights about your assets and their ecosystem dependencies.
          </Description>
        </div>
        
        <Controls>
          <SelectControl>
            <span>Time range:</span>
            <select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
              <option value="1m">Last month</option>
              <option value="3m">Last 3 months</option>
              <option value="6m">Last 6 months</option>
              <option value="1y">Last year</option>
              <option value="all">All time</option>
            </select>
          </SelectControl>
          
          <RefreshButton onClick={refreshData} disabled={isRefreshing}>
            <FiRefreshCw className={isRefreshing ? 'spinning' : ''} />
            {isRefreshing ? 'Refreshing...' : 'Refresh data'}
          </RefreshButton>
        </Controls>
      </AnalyticsHeader>
      
      <KpiRow>
        <KpiCard $color="primary" $trend={0}>
          <div className="icon">
            <FiBarChart2 />
          </div>
          <h3>{assets.length}</h3>
          <p>Total Assets</p>
        </KpiCard>
        
        <KpiCard $color="success" $trend={4.2}>
          <div className="icon">
            <FiDollarSign />
          </div>
          <h3>${(totalAssetValue / 1000000).toFixed(1)}M</h3>
          <p>Total Asset Value</p>
          <div className="trend">
            <FiTrendingUp /> 4.2%
          </div>
        </KpiCard>
        
        <KpiCard $color="error" $trend={8.3}>
          <div className="icon">
            <FiAlertTriangle />
          </div>
          <h3>${(atRiskValue / 1000000).toFixed(1)}M</h3>
          <p>Value at Risk</p>
          <div className="trend">
            <FiTrendingUp /> 8.3%
          </div>
        </KpiCard>
        
        <KpiCard $color="warning" $trend={5.7}>
          <div className="icon">
            <FiBarChart2 />
          </div>
          <h3>{totalEcosystemDependencies}</h3>
          <p>Ecosystem Dependencies</p>
          <div className="trend">
            <FiTrendingUp /> 5.7%
          </div>
        </KpiCard>
      </KpiRow>
      
      <ChartGrid>
        <ChartCard>
          <ChartHeader>
            <h3>Asset Risk Distribution</h3>
            <div className="actions">
              <button className="icon-button">
                <FiRefreshCw />
              </button>
            </div>
          </ChartHeader>
          <ChartDescription>
            Distribution of assets by risk level, based on climate vulnerability assessments
          </ChartDescription>
          <ChartContent>
            <AssetRiskChart />
          </ChartContent>
        </ChartCard>
        
        <ChartCard>
          <ChartHeader>
            <h3>Asset Type Distribution</h3>
            <div className="actions">
              <button className="icon-button">
                <FiRefreshCw />
              </button>
            </div>
          </ChartHeader>
          <ChartDescription>
            Distribution of assets by property type and business category
          </ChartDescription>
          <ChartContent>
            <AssetTypeChart />
          </ChartContent>
        </ChartCard>
        
        <ChartCard>
          <ChartHeader>
            <h3>Ecosystem Dependencies by Risk</h3>
            <div className="actions">
              <button className="icon-button">
                <FiRefreshCw />
              </button>
            </div>
          </ChartHeader>
          <ChartDescription>
            Distribution of ecosystem dependencies by risk level
          </ChartDescription>
          <ChartContent>
            <EcosystemRiskChart />
          </ChartContent>
        </ChartCard>
        
        <ChartCard>
          <ChartHeader>
            <h3>Asset Value by Location</h3>
            <div className="actions">
              <button className="icon-button">
                <FiRefreshCw />
              </button>
            </div>
          </ChartHeader>
          <ChartDescription>
            Geographic distribution of asset value across different regions
          </ChartDescription>
          <ChartContent>
            <AssetValueLocationChart />
          </ChartContent>
        </ChartCard>
      </ChartGrid>
      
      <FullWidthCard>
        <ChartHeader>
          <h3>Top Assets by Risk</h3>
          <div className="actions">
            <button className="icon-button">
              <FiRefreshCw />
            </button>
          </div>
        </ChartHeader>
        <ChartDescription>
          Assets with the highest climate risk scores, requiring priority attention
        </ChartDescription>
        
        <TopAssetsGrid>
          {assets
            .sort((a, b) => b.riskScore - a.riskScore)
            .slice(0, 4)
            .map((asset, index) => (
              <AssetItem key={asset.id}>
                <h4>{asset.name}</h4>
                <p>{asset.type}</p>
                <ProgressCircle $percentage={(index + 1) * 25} />
              </AssetItem>
            ))}
        </TopAssetsGrid>
      </FullWidthCard>
    </AnalyticsContainer>
  );
};

// Chart Components
const AssetRiskChart = () => {
  // Count assets by risk level
  const riskCounts = assets.reduce((acc, asset) => {
    acc[asset.risk] = (acc[asset.risk] || 0) + 1;
    return acc;
  }, {});
  
  const data = {
    labels: ['Critical', 'High', 'Medium', 'Low'],
    datasets: [{
      data: [
        riskCounts.critical || 0,
        riskCounts.high || 0,
        riskCounts.medium || 0,
        riskCounts.low || 0
      ],
      backgroundColor: [
        'rgba(239, 68, 68, 0.7)',
        'rgba(245, 158, 11, 0.7)',
        'rgba(59, 130, 246, 0.7)',
        'rgba(16, 185, 129, 0.7)'
      ]
    }]
  };
  
  return <Pie data={data} options={{ maintainAspectRatio: false }} />;
};

const AssetTypeChart = () => {
  // Count assets by type
  const typeCounts = assets.reduce((acc, asset) => {
    acc[asset.type] = (acc[asset.type] || 0) + 1;
    return acc;
  }, {});
  
  const data = {
    labels: Object.keys(typeCounts),
    datasets: [{
      data: Object.values(typeCounts),
      backgroundColor: [
        'rgba(16, 185, 129, 0.7)',
        'rgba(59, 130, 246, 0.7)',
        'rgba(245, 158, 11, 0.7)'
      ]
    }]
  };
  
  return <Doughnut data={data} options={{ maintainAspectRatio: false }} />;
};

const EcosystemRiskChart = () => {
  // Count ecosystem dependencies by risk level
  const riskCounts = { critical: 0, high: 0, medium: 0, low: 0 };
  
  assets.forEach(asset => {
    asset.ecosystemDependencies.forEach(dep => {
      riskCounts[dep.risk]++;
    });
  });
  
  const data = {
    labels: ['Critical', 'High', 'Medium', 'Low'],
    datasets: [{
      data: Object.values(riskCounts),
      backgroundColor: [
        'rgba(239, 68, 68, 0.7)',
        'rgba(245, 158, 11, 0.7)',
        'rgba(59, 130, 246, 0.7)',
        'rgba(16, 185, 129, 0.7)'
      ]
    }]
  };
  
  return <Pie data={data} options={{ maintainAspectRatio: false }} />;
};

const AssetValueLocationChart = () => {
  // Aggregate values by location
  const valueByLocation = assets.reduce((acc, asset) => {
    acc[asset.location] = (acc[asset.location] || 0) + asset.value;
    return acc;
  }, {});
  
  const data = {
    labels: Object.keys(valueByLocation),
    datasets: [{
      label: 'Asset Value ($M)',
      data: Object.values(valueByLocation).map(val => val / 1000000),
      backgroundColor: 'rgba(59, 130, 246, 0.7)'
    }]
  };
  
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Value ($ Millions)'
        }
      }
    }
  };
  
  return <Bar data={data} options={options} />;
};

export default Analytics; 